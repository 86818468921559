//格式化时间
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const moment = (fmt: any, date: any) => {
  const o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length),
    );
  for (const k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length),
      );
  return fmt;
};

//去除首尾空格
const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '');
};

//截取字符串
const interceptString = (str: string, firstStr: string, secondStr: string) => {
  if (str === '' || str === null || str === undefined) {
    return '';
  }
  if (str.indexOf(firstStr) < 0) {
    return '';
  }
  const subFirstStr = str.substring(
    str.indexOf(firstStr) + firstStr.length,
    str.length,
  );
  const subSecondStr = subFirstStr.substring(0, subFirstStr.indexOf(secondStr));
  return subSecondStr;
};

export {moment, trim, interceptString};
