/* eslint-disable */

import {ObjectId} from 'bson'

export namespace Order {
  export const group: string = 'MaxCI';
  export const kind: string = 'Order';
  export const version: string = 'v1';
 export const type: Order = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 订单
 */
export interface Order {
  /**
   * 规格
   */
  spec:
    | MallOrderSpec
    | AgentHelpOrderSpec
    | AgentDeliveryOrderSpec
    | WithdrawalOrderSpec
    | UsedOrderSpec
    | MemberCardOrderSpec
    | RechargeOrderSpec;
  /**
   * 状态
   */
  status?:
    | MallOrderStatus
    | AgentOrderStatus
    | WithdrawalOrderStatus
    | UsedOrderStatus
    | MemberCardOrderStatus
    | RechargeOrderStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 商城订单规格
 */
export interface MallOrderSpec {
  /**
   * 类型
   */
  type: "商城";
  /**
   * 商品列表
   */
  items?: OrderItem[];
  /**
   * 收货信息
   */
  receiveInfo?: MallOrderDeliveryReceiveInfo | SelfPickReceiveInfo;
  /**
   * 满减
   */
  fullReduction?: {
    [k: string]: unknown;
  };
  coupon?: OrderCoupon;
  serial?: SerialNumber;
  amount?: MallOrderAmount;
  /**
   * 备注
   */
  remarks?: string;
  refund?: MallOrderAmount1;
  /**
   * 邀请人ID
   */
  inviterId?: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 订单商品
 */
export interface OrderItem {
  sku: ProductSKUSnapshot;
  /**
   * 属性
   */
  attributes: string[];
  /**
   * 数量
   */
  count: number;
  snapshot: ProductSnapshot;
  /**
   * 赠品
   */
  isGiving?: boolean;
  shopDiscount: OrderItemDiscount;
  applicationDiscount: OrderItemDiscount1;
  /**
   * 产品ID
   */
  productId: ObjectId;
}
/**
 * 商品库存单位
 */
export interface ProductSKUSnapshot {
  /**
   * 名称
   */
  name: string;
  /**
   * 主图
   */
  image?: string;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 成本价
   */
  costPrice?: number;
}
/**
 * 商品快照
 */
export interface ProductSnapshot {
  /**
   * 名称
   */
  name: string;
  /**
   * 商品图片(最多5张)
   */
  images:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string];
  /**
   * 商品规格
   */
  specs: ProductAttribute[];
  /**
   * 商品属性
   */
  attributes: ProductAttribute[];
  /**
   * 商品库存单位
   */
  skus: ProductSKUSnapshot1[];
  /**
   * 包装费
   */
  packingFee: number;
  purchaseQuantity?: PurchaseQuantity;
  /**
   * 标签
   */
  labels: string[];
  /**
   * 简介
   */
  description?: string;
  /**
   * 佣金比例
   */
  commissionRate?: number;
}
/**
 * 商品属性
 */
export interface ProductAttribute {
  /**
   * 名称
   */
  name: string;
  /**
   * 可选值列表
   */
  values: [string, ...string[]];
}
/**
 * 商品库存单位
 */
export interface ProductSKUSnapshot1 {
  /**
   * 名称
   */
  name: string;
  /**
   * 主图
   */
  image?: string;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 成本价
   */
  costPrice?: number;
}
/**
 * 购买数量
 */
export interface PurchaseQuantity {
  /**
   * 最小购买数量
   */
  min?: number;
  /**
   * 最大购买数量
   */
  max?: number;
}
/**
 * 店铺优惠
 */
export interface OrderItemDiscount {
  /**
   * 满减
   */
  fullReduction: number;
  /**
   * 优惠券
   */
  coupon: number;
}
/**
 * 应用优惠
 */
export interface OrderItemDiscount1 {
  /**
   * 满减
   */
  fullReduction: number;
  /**
   * 优惠券
   */
  coupon: number;
}
/**
 * 商城配送收货信息
 */
export interface MallOrderDeliveryReceiveInfo {
  /**
   * 类型
   */
  service: "应用" | "店铺";
  /**
   * 类型
   */
  type: "配送";
  info: DeliveryInfo;
  /**
   * 期望时间
   */
  expectTime?: Date;
}
/**
 * 收货信息
 */
export interface DeliveryInfo {
  /**
   * 联系人
   */
  contact?: string;
  /**
   * 性别
   */
  gender: "男" | "女" | "未知";
  /**
   * 电话
   */
  phone: string;
  /**
   * 地址
   */
  address: string;
  geometry: Point;
  /**
   * 详情
   */
  details?: string;
}
/**
 * 地理位置
 */
export interface Point {
  /**
   * 类型
   */
  type: "Point";
  /**
   * 坐标数据
   */
  coordinates: [number, number];
}
/**
 * 自取收货信息
 */
export interface SelfPickReceiveInfo {
  /**
   * 类型
   */
  type: "自取";
  /**
   * 期望时间
   */
  expectTime?: Date;
  info: DeliveryPhone;
}
/**
 * 收货信息
 */
export interface DeliveryPhone {
  /**
   * 联系电话
   */
  phone: string;
}
/**
 * 优惠券
 */
export interface OrderCoupon {
  /**
   * 优惠券ID
   */
  couponId: ObjectId;
  /**
   * 活动ID
   */
  activityId: ObjectId;
  /**
   * 首单
   */
  firstOrder: string;
  /**
   * 包含产品列表
   */
  includProducts: ObjectId[];
  /**
   * 排除产品列表
   */
  excludProducts: ObjectId[];
  discount: unknown;
}
/**
 * 序号
 */
export interface SerialNumber {
  /**
   * 日期
   */
  date: string;
  /**
   * 序号
   */
  number: number;
}
/**
 * 费用信息
 */
export interface MallOrderAmount {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 满减金额
 */
export interface MallDiscount {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 优惠券金额
 */
export interface MallDiscount1 {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 退款金额
 */
export interface MallOrderAmount1 {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 跑腿帮忙订单规格
 */
export interface AgentHelpOrderSpec {
  /**
   * 跑腿类型
   */
  agentType: "帮忙";
  /**
   * 类型
   */
  type: "跑腿";
  /**
   * 备注
   */
  remarks: string;
  /**
   * 图片
   */
  images?: [] | [string] | [string, string] | [string, string, string];
  /**
   * 期望时间
   */
  expectTime?: Date;
  /**
   * 跑腿费
   */
  fee: number;
  amount?: AgentOrderAmount;
  /**
   * 用户手机号码
   */
  userPhone?: string;
  /**
   * 任务员ID
   */
  taskerId?: ObjectId;
  /**
   * 任务员手机号码
   */
  taskerPhone?: string;
  refund?: AgentOrderAmount1;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 费用信息
 */
export interface AgentOrderAmount {
  /**
   * 跑腿费
   */
  agent: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 退款金额
 */
export interface AgentOrderAmount1 {
  /**
   * 跑腿费
   */
  agent: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 跑腿配送订单规格
 */
export interface AgentDeliveryOrderSpec {
  /**
   * 跑腿类型
   */
  agentType: "代取" | "代送" | "代购";
  sourceInfo: DeliveryInfo1;
  targetInfo: DeliveryInfo2;
  /**
   * 类型
   */
  type: "跑腿";
  /**
   * 备注
   */
  remarks: string;
  /**
   * 图片
   */
  images?: [] | [string] | [string, string] | [string, string, string];
  /**
   * 期望时间
   */
  expectTime?: Date;
  /**
   * 跑腿费
   */
  fee: number;
  amount?: AgentOrderAmount2;
  /**
   * 用户手机号码
   */
  userPhone?: string;
  /**
   * 任务员ID
   */
  taskerId?: ObjectId;
  /**
   * 任务员手机号码
   */
  taskerPhone?: string;
  refund?: AgentOrderAmount3;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 源信息
 */
export interface DeliveryInfo1 {
  /**
   * 联系人
   */
  contact?: string;
  /**
   * 性别
   */
  gender: "男" | "女" | "未知";
  /**
   * 电话
   */
  phone: string;
  /**
   * 地址
   */
  address: string;
  geometry: Point;
  /**
   * 详情
   */
  details?: string;
}
/**
 * 目标信息
 */
export interface DeliveryInfo2 {
  /**
   * 联系人
   */
  contact?: string;
  /**
   * 性别
   */
  gender: "男" | "女" | "未知";
  /**
   * 电话
   */
  phone: string;
  /**
   * 地址
   */
  address: string;
  geometry: Point;
  /**
   * 详情
   */
  details?: string;
}
/**
 * 费用信息
 */
export interface AgentOrderAmount2 {
  /**
   * 跑腿费
   */
  agent: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 退款金额
 */
export interface AgentOrderAmount3 {
  /**
   * 跑腿费
   */
  agent: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 提现订单规格
 */
export interface WithdrawalOrderSpec {
  /**
   * 类型
   */
  type: "提现";
  /**
   * 金额
   */
  amount: number;
  /**
   * 用户手机号码
   */
  userPhone?: string;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 二手订单规格
 */
export interface UsedOrderSpec {
  /**
   * 类型
   */
  type: "二手";
  /**
   * 卖家ID
   */
  sellerId: ObjectId;
  /**
   * 卖家手机号
   */
  sellerPhone?: string;
  snapshot?: UsedProductSnapshot;
  deliveryInfo: DeliveryInfo3;
  amount?: UsedOrderAmount;
  /**
   * 备注
   */
  remarks?: string;
  refund?: UsedOrderAmount1;
  /**
   * 二手商品ID
   */
  usedProductId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 商品快照
 */
export interface UsedProductSnapshot {
  /**
   * 简介
   */
  remarks?: string;
  /**
   * 图片介绍(最多10张)
   */
  images:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string]
    | [string, string, string, string, string, string]
    | [string, string, string, string, string, string, string]
    | [string, string, string, string, string, string, string, string]
    | [string, string, string, string, string, string, string, string, string]
    | [string, string, string, string, string, string, string, string, string, string];
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 邮费
   */
  postage: number;
}
/**
 * 收货信息
 */
export interface DeliveryInfo3 {
  /**
   * 联系人
   */
  contact?: string;
  /**
   * 性别
   */
  gender: "男" | "女" | "未知";
  /**
   * 电话
   */
  phone: string;
  /**
   * 地址
   */
  address: string;
  geometry: Point;
  /**
   * 详情
   */
  details?: string;
}
/**
 * 费用信息
 */
export interface UsedOrderAmount {
  /**
   * 邮费
   */
  postage: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 退款金额
 */
export interface UsedOrderAmount1 {
  /**
   * 邮费
   */
  postage: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 会员卡订单规格
 */
export interface MemberCardOrderSpec {
  /**
   * 类型
   */
  type: "会员卡";
  snapshot?: MemberCardSnapshot;
  amount?: MemberCardOrderAmount;
  /**
   * 备注
   */
  remarks?: string;
  /**
   * 会员卡ID
   */
  memberCardId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 会员卡快照
 */
export interface MemberCardSnapshot {
  /**
   * 名称
   */
  name: string;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 有效天数
   */
  validDate: number;
  /**
   * 简介
   */
  remarks?: string;
}
/**
 * 费用信息
 */
export interface MemberCardOrderAmount {
  /**
   * 会员卡金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 充值订单规格
 */
export interface RechargeOrderSpec {
  /**
   * 类型
   */
  type: "充值";
  /**
   * 手机号码
   */
  phone: string;
  snapshot?: PrepaidRefillSnapshot;
  amount?: RechargeOrderAmount;
  /**
   * 备注
   */
  remarks?: string;
  refund?: RechargeOrderAmount1;
  /**
   * 充值ID
   */
  rechargeId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 快照
 */
export interface PrepaidRefillSnapshot {
  /**
   * 名称
   */
  name: string;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 简介
   */
  remarks?: string;
}
/**
 * 费用信息
 */
export interface RechargeOrderAmount {
  /**
   * 话费充值金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 退款
 */
export interface RechargeOrderAmount1 {
  /**
   * 话费充值金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 商城订单状态
 */
export interface MallOrderStatus {
  payInfo?: OrderPayInfo;
  deliveryInfo?: MallOrderDeliveryInfo;
  settlement?: MallOrderSettlement;
  /**
   * 当前阶段
   */
  phase?: "下单" | "确认" | "支付" | "取消" | "接单" | "打包" | "配送" | "完成" | "退款" | "结算";
  conditions: Condition[];
}
/**
 * 支付信息
 */
export interface OrderPayInfo {
  /**
   * 支付商户类型
   */
  payMerchantType?: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
}
/**
 * 配送信息
 */
export interface MallOrderDeliveryInfo {
  /**
   * 配送订单Id
   */
  deliveryOrderId?: string;
  riderInfo?: DeliveryUserInfo;
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: string;
}
/**
 * 配送员
 */
export interface DeliveryUserInfo {
  /**
   * 姓名
   */
  name: string;
  /**
   * 手机号
   */
  phone: string;
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 结算信息
 */
export interface MallOrderSettlement {
  input?: MallOrderSettlementInput;
  output?: MallOrderSettlementOutput;
}
/**
 * 结算入账信息
 */
export interface MallOrderSettlementInput {
  /**
   * 结算金额
   */
  settlementAmount: number;
  /**
   * 补贴金额
   */
  allowanceAmount: number;
  /**
   * 技术服务费
   */
  tecServiceAmount: number;
  /**
   * 配送服务费
   */
  deliveryServiceAmount: number;
  /**
   * 应用分成
   */
  applicationAmount: number;
  /**
   * 店铺分成
   */
  shopAmount: number;
  tecServiceRate: ServiceRate;
  deliveryServiceRate: ServiceRate1;
}
/**
 * 技术服务费率
 */
export interface ServiceRate {
  /**
   * 比例(万分之)
   */
  rate: number;
  /**
   * 保底(分)
   */
  minimum: number;
}
/**
 * 配送服务费率
 */
export interface ServiceRate1 {
  /**
   * 比例(万分之)
   */
  rate: number;
  /**
   * 保底(分)
   */
  minimum: number;
}
/**
 * 提现信息
 */
export interface MallOrderSettlementOutput {
  /**
   * 结算ID
   */
  settlementId: ObjectId;
  /**
   * 应用提现
   */
  applicationAmount: number;
  /**
   * 店铺提现
   */
  shopAmount: number;
  /**
   * 完成
   */
  completed: boolean;
}
/**
 * 跑腿订单状态
 */
export interface AgentOrderStatus {
  payInfo?: OrderPayInfo1;
  /**
   * 确认码
   */
  confirmCode?: string;
  /**
   * 拍照凭证
   */
  credentials?: [] | [string] | [string, string] | [string, string, string];
  /**
   * 当前阶段
   */
  phase?: "下单" | "支付" | "取消" | "接单" | "配送" | "完成" | "退款" | "无效" | "结算";
  conditions: Condition[];
}
/**
 * 支付信息
 */
export interface OrderPayInfo1 {
  /**
   * 支付商户类型
   */
  payMerchantType?: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
}
/**
 * 提现订单状态
 */
export interface WithdrawalOrderStatus {
  /**
   * 当前阶段
   */
  phase?: "下单" | "取消" | "完成" | "无效";
  conditions: Condition[];
}
/**
 * 二手订单状态
 */
export interface UsedOrderStatus {
  payInfo?: OrderPayInfo2;
  expressInfo?: OrderExpressInfo;
  /**
   * 当前阶段
   */
  phase?: "下单" | "支付" | "取消" | "配送" | "完成" | "退款" | "无效";
  conditions: Condition[];
}
/**
 * 支付信息
 */
export interface OrderPayInfo2 {
  /**
   * 支付商户类型
   */
  payMerchantType?: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
}
/**
 * 快递信息
 */
export interface OrderExpressInfo {
  /**
   * 快递单号
   */
  number: string;
  /**
   * 快递公司
   */
  company: string;
}
/**
 * 会员卡状态
 */
export interface MemberCardOrderStatus {
  payInfo?: OrderPayInfo3;
  /**
   * 当前阶段
   */
  phase?: "下单" | "支付" | "取消" | "完成" | "退款" | "无效";
  conditions: Condition[];
}
/**
 * 支付信息
 */
export interface OrderPayInfo3 {
  /**
   * 支付商户类型
   */
  payMerchantType?: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
}
/**
 * 充值状态
 */
export interface RechargeOrderStatus {
  /**
   * 充值交易ID
   */
  rechargeTransactionId?: string;
  payInfo?: OrderPayInfo4;
  /**
   * 当前阶段
   */
  phase?: "下单" | "支付" | "充值" | "取消" | "完成" | "退款" | "无效";
  conditions: Condition[];
}
/**
 * 支付信息
 */
export interface OrderPayInfo4 {
  /**
   * 支付商户类型
   */
  payMerchantType?: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
