/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'canteen': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M404.896 414.752c-9.6 6.848-13.696 18.112-10.304 28.736l0.352 1.408c5.824 18.08 29.44 25.312 45.536 13.696 50.624-36 50.624-82.528 50.624-107.52V158.464c0-25.696 19.872-43.168 42.432-54.496a26.88 26.88 0 0 0 15.424-23.936c0-20.896-23.936-33.92-43.456-23.968-37.312 18.816-73.216 50.336-73.216 102.656v192.704c0 20.864-0.32 43.808-27.392 63.328z m-130.752 0c-9.568 6.848-13.664 18.112-10.24 28.736l0.352 1.408c5.824 18.08 29.44 25.312 45.536 13.696 50.624-36 50.624-82.528 50.624-107.52V158.464c0-25.696 19.84-43.168 42.432-54.496a26.88 26.88 0 0 0 15.424-23.936c0-20.896-23.968-33.92-43.488-23.968-37.28 18.816-73.248 50.336-73.248 102.656v192.704c-0.32 20.864-0.32 43.808-27.392 63.328z m-138.176 30.432c5.792 18.144 29.408 25.344 45.504 13.696 50.624-35.936 50.624-82.464 50.624-107.424V158.464c0-25.696 19.872-43.168 42.464-54.496a26.848 26.848 0 0 0 15.36-23.904c0-20.896-23.968-33.92-43.456-23.968-37.312 18.816-73.216 50.336-73.216 102.656v192.704c0 20.832 0 44.096-27.392 63.616-9.568 6.848-13.664 18.112-10.272 28.736l0.384 1.376zM606.432 202.56h378.112c17.152 0 38.016-21.888 38.016-37.632s-20.864-37.632-38.016-37.632H606.432c-17.088 0-37.952 21.888-37.952 37.632s21.216 37.632 37.952 37.632zM1.76 544.448c0 166.976 114.656 309.376 275.168 363.04v81.824h470.208V907.84c160.48-53.696 275.136-196.064 275.136-363.04v-42.112H1.76v41.76z m982.816-266.912H606.432c-17.088 0-37.952 21.92-37.952 37.632 0 15.776 20.832 37.632 37.952 37.632h378.112c17.152 0 38.016-21.888 38.016-37.632-0.32-15.744-21.216-37.632-37.984-37.632zM39.424 202.56H77.44c17.12 0 37.984-21.888 37.984-37.632s-21.28-37.312-38.016-37.312H39.424c-16.736 0-37.664 21.92-37.664 37.696 0 15.744 20.896 37.248 37.664 37.248z m0 149.92H77.44c17.12 0 37.984-21.92 37.984-37.664s-20.896-37.6-38.016-37.6H39.424c-17.12 0-37.984 21.888-37.984 37.632 0.32 16.096 21.216 37.632 37.984 37.632z m0 0" p-id="12206"></path>'
  }
})
