
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'AppMain',
})
export default class extends Vue {
  private include = 'orderList';
  get name() {
    return this.$route.name;
  }
  get key() {
    return this.$route.fullPath;
  }
}
