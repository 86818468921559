/* eslint-disable */

import {ObjectId} from 'bson'

import {AnyObject} from '@warehouse/core'

export namespace Channel {
  export const group: string = 'MaxCI';
  export const kind: string = 'Channel';
  export const version: string = 'v1';
 export const type: Channel = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 渠道
 */
export interface Channel {
  /**
   * 规格
   */
  spec: WechatMiniAppChannelSpec | WechatPublicChannelSpec | AppChannelSpec | WebChannelSpec | AlipayMiniAppSpec;
  status?: ChannelStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 微信小程序渠道规格
 */
export interface WechatMiniAppChannelSpec {
  /**
   * 类型
   */
  type: "微信小程序";
  wechat?: WechatMiniAppChannelData;
  /**
   * 订阅消息模板ID
   */
  subscribeMessageTemplateId?: string;
  /**
   * 渠道名称
   */
  name: string;
  /**
   * UI模板
   */
  templateId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 数据
 */
export interface WechatMiniAppChannelData {
  /**
   * 应用ID
   */
  appId: string;
  /**
   * 昵称
   */
  nickName: string;
  /**
   * 头像
   */
  headImg?: string;
  /**
   * 小程序类型
   */
  serviceTypeInfo: number;
  /**
   * 小程序认证类型
   */
  verifyTypeInfo: number;
  /**
   * 原始ID
   */
  userName: string;
  /**
   * 主体名称
   */
  principalName: string;
  /**
   * 帐号介绍
   */
  signature: string;
  /**
   * 用以了解功能的开通状况（0代表未开通，1代表已开通），详见business_info 说明
   */
  businessInfo: AnyObject;
  /**
   * 二维码图片
   */
  qrcodeUrl: string;
  /**
   * 小程序配置，根据这个字段判断是否为小程序类型授权
   */
  configInfo: AnyObject;
  /**
   * 授权给开发者的权限集列表
   */
  funcInfo: AnyObject[];
  /**
   * 刷新令牌
   */
  refreshToken: string;
  /**
   * 访问令牌
   */
  accessToken: string;
  /**
   * 访问令牌有效期
   */
  accessTokenExpiration?: Date;
}
/**
 * 微信公众号渠道规格
 */
export interface WechatPublicChannelSpec {
  /**
   * 类型
   */
  type: "微信公众号";
  wechat?: WechatAppData;
  /**
   * 渠道名称
   */
  name: string;
  /**
   * UI模板
   */
  templateId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 数据
 */
export interface WechatAppData {
  /**
   * 应用ID
   */
  appId: string;
  /**
   * 应用秘钥
   */
  appSecret: string;
}
/**
 * 移动应用渠道规格
 */
export interface AppChannelSpec {
  /**
   * 类型
   */
  type: "移动应用";
  /**
   * DCloud APPID
   */
  dcloudId?: string;
  android?: AppChannelAndroid;
  /**
   * 高德应用API密钥
   */
  amapKey?: string;
  wechat?: WechatAppData1;
  /**
   * 渠道名称
   */
  name: string;
  /**
   * UI模板
   */
  templateId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * Android应用
 */
export interface AppChannelAndroid {
  /**
   * 包名
   */
  package: string;
  key: AppChannelAndroidKey;
  /**
   * DCloud APPKEY
   */
  dcloudKey: string;
  resource: AppChannelAndroidResource;
}
/**
 * 证书
 */
export interface AppChannelAndroidKey {
  /**
   * 内容
   */
  content: string;
  /**
   * 密码
   */
  password: string;
  /**
   * 别名
   */
  alias: string;
}
/**
 * 资源数据
 */
export interface AppChannelAndroidResource {
  /**
   * 应用图标
   */
  icon: string;
  /**
   * 应用通知图标
   */
  push?: string;
  /**
   * 应用开屏图片
   */
  splash?: string;
}
/**
 * 微信应用数据
 */
export interface WechatAppData1 {
  /**
   * 应用ID
   */
  appId: string;
  /**
   * 应用秘钥
   */
  appSecret: string;
}
/**
 * 网站渠道规格
 */
export interface WebChannelSpec {
  /**
   * 类型
   */
  type: "网站";
  wechat?: WechatAppData2;
  /**
   * 渠道名称
   */
  name: string;
  /**
   * UI模板
   */
  templateId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 数据
 */
export interface WechatAppData2 {
  /**
   * 应用ID
   */
  appId: string;
  /**
   * 应用秘钥
   */
  appSecret: string;
}
/**
 * 支付宝小程序渠道规格
 */
export interface AlipayMiniAppSpec {
  /**
   * 类型
   */
  type: "支付宝小程序";
  alipay?: AlipayMiniAppChannelData;
  /**
   * 渠道名称
   */
  name: string;
  /**
   * UI模板
   */
  templateId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 数据
 */
export interface AlipayMiniAppChannelData {
  /**
   * 应用ID
   */
  appId?: string;
  /**
   * 应用授权令牌,永久有效
   */
  appAuthToken?: string;
  /**
   * 应用授权令牌失效时间，单位到秒
   */
  expiresIn?: string;
  /**
   * 刷新令牌
   */
  appRefreshToken?: string;
  /**
   * 刷新令牌失效时间，单位到秒
   */
  reExpiresIn?: string;
  /**
   * AES密钥
   */
  aesKey?: string;
  /**
   * 小程序应用名称
   */
  appName?: string;
  /**
   * 小程序应用英文名称
   */
  appEnglishName?: string;
  /**
   * 小程序应用简介，一句话描述小程序功能
   */
  appSlogan?: string;
  /**
   * 小程序应用logo图标
   */
  appLogo?: string;
  /**
   * 类目名称
   */
  categoryNames?: string;
  /**
   * 小程序应用描述
   */
  appDesc?: string;
  /**
   * 小程序客服电话
   */
  servicePhone?: string;
  /**
   * 小程序客服邮箱
   */
  serviceEmail?: string;
  /**
   * 域白名单
   */
  safeDomains?: {
    [k: string]: unknown;
  }[];
  /**
   * 小程序应用描述
   */
  packageNames?: {
    [k: string]: unknown;
  }[];
}
/**
 * 状态
 */
export interface ChannelStatus {
  /**
   * 预授权码
   */
  preAuthCode?: string;
  wechatCode?: WechatMiniAppCode;
  alipayCode?: AlipayMiniAppCode;
  /**
   * 企业代码
   */
  enterpriseCode?: string;
  /**
   * 当前阶段
   */
  phase?: "就绪" | "未授权" | "删除";
  conditions: Condition[];
}
/**
 * 微信小程序代码
 */
export interface WechatMiniAppCode {
  /**
   * 模板ID
   */
  templateId: number;
  /**
   * 审核拒绝截图
   */
  screenShots?: string[];
  /**
   * 当前阶段
   */
  phase?: "上传" | "提交" | "拒绝" | "延期" | "通过" | "发布";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 支付宝小程序代码
 */
export interface AlipayMiniAppCode {
  /**
   * 版本号
   */
  appVersion: string;
  baseAuditReason?: AlipayMiniAppAuditReason;
  promoteAuditReason?: AlipayMiniAppAuditReason1;
  /**
   * 当前阶段
   */
  phase?: "上传" | "提交" | "拒绝" | "退回" | "通过" | "发布";
  conditions: Condition[];
}
/**
 * 准入审核原因
 */
export interface AlipayMiniAppAuditReason {
  /**
   * 审核图片列表
   */
  auditImages?: string[];
  /**
   * 审核图片列表
   */
  memos?: AlipayMiniAppAuditReasonMemo[];
}
/**
 * 支付宝小程序审核驳回原因列表
 */
export interface AlipayMiniAppAuditReasonMemo {
  /**
   * 驳回原因
   */
  memo?: string;
  /**
   * 规则图片链接
   */
  memoImageList?: string[];
}
/**
 * 营销审核驳回原因
 */
export interface AlipayMiniAppAuditReason1 {
  /**
   * 审核图片列表
   */
  auditImages?: string[];
  /**
   * 审核图片列表
   */
  memos?: AlipayMiniAppAuditReasonMemo[];
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
