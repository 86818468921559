/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'form': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M603.792613 68.64952 409.943626 68.64952c-23.77652 0-42.785455 21.154809-42.785455 47.303352 0 26.208918 19.043728 47.426149 42.785455 47.426149l195.048301 0c22.580275 0 41.617863-21.217231 41.617863-47.426149C646.614907 89.803305 627.599831 68.64952 603.792613 68.64952L603.792613 68.64952 603.792613 68.64952zM814.280558 100.221586l-96.531544 0c0.981351 5.183046 1.548262 10.425444 1.548262 15.731286 0.029676 39.817867-28.855188 79.006402-63.21474 79.006402L359.51919 194.959274c-36.160571 0-65.084321-39.188534-65.084321-79.006402 0.034792-5.305843 0.601704-10.54824 1.583055-15.731286l-97.698113 0c-78.533634 0-97.828073 44.523029-97.828073 108.750843l0 636.20993c0 68.995397 27.629267 107.61088 103.758132 107.61088l604.094488 0c76.129888 0 113.137757-30.27656 113.137757-107.576087L921.482115 208.971405c0.00614-64.221674-34.641985-108.744703-107.200534-108.744703l0 0L814.281581 100.221586zM715.102744 728.380142 295.321052 728.380142c-15.47239 0-30.918173-18.980283-30.918173-34.477232 0-15.44476 13.072737-28.675086 28.54615-28.675086l418.5834 0c15.468296 0 28.54001 12.025894 29.713742 28.675086C741.246171 709.404975 730.571041 728.380142 715.102744 728.380142L715.102744 728.380142 715.102744 728.380142zM716.808596 559.925522 297.021787 559.925522c-15.474436 0-25.326828-16.636912-25.326828-32.145118 0-15.503089 13.078877-31.007201 28.54615-31.007201l418.5834 0c15.474436 0 28.54615 14.364149 29.713742 31.007201C748.539274 543.283494 732.24824 559.925522 716.808596 559.925522L716.808596 559.925522 716.808596 559.925522zM716.808596 386.915144 297.021787 386.915144c-15.474436 0-25.326828-16.643052-25.326828-32.145118 0-15.504112 13.078877-31.007201 28.54615-31.007201l418.5834 0c15.474436 0 28.54615 14.370289 29.713742 31.007201C748.539274 370.272092 732.24824 386.915144 716.808596 386.915144L716.808596 386.915144 716.808596 386.915144zM716.808596 386.915144" p-id="6927"></path>'
  }
})
