/* eslint-disable */

import {ObjectId} from 'bson'

export namespace AlipayCodeRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'AlipayCodeRequest';
  export const version: string = 'v1';
 export const type: AlipayCodeRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 支付宝代码请求
 */
export interface AlipayCodeRequest {
  /**
   * 规格
   */
  spec: AlipayCodeRequestSpec | AlipayCodeRequestUploadSpec | AlipayCodeRequestAuditedCancelSpec;
  status?: AlipayCodeStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 支付宝代码请求规格
 */
export interface AlipayCodeRequestSpec {
  /**
   * 请求
   */
  type: "预览" | "审核" | "撤回" | "发布" | "回滚";
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 支付宝代码上传请求规格
 */
export interface AlipayCodeRequestUploadSpec {
  /**
   * 请求
   */
  type: "上传";
  /**
   * 小程序模板版本号
   */
  templateVersion: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 支付宝代码退回开发请求规格
 */
export interface AlipayCodeRequestAuditedCancelSpec {
  /**
   * 请求
   */
  type: "退回开发";
  /**
   * 商家小程序版本号
   */
  appVersion: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface AlipayCodeStatus {
  /**
   * 二维码
   */
  qrcode?: string;
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
