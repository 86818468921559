import Vue from 'vue';
import Router, {RouteConfig} from 'vue-router';
import Layout from '@/layout/index.vue';

Vue.use(Router);

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: {hidden: true, title: '登录'},
  },
  {
    path: '/authorize',
    component: () => import('@/views/authorize/index.vue'),
    meta: {hidden: true, title: '授权'},
  },
  {
    path: '/bindphone/:id',
    component: () => import('@/views/bindphone/index.vue'),
    meta: {hidden: true, title: '绑定手机号'},
  },
  {
    path: '/404',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
    meta: {hidden: true},
  },
  {
    path: '*',
    redirect: '/404',
    meta: {hidden: true},
  },
  //首页
  {
    path: '/',
    component: () => import('@/views/index/index.vue'),
    meta: {hidden: true},
  },
  //主页
  {
    path: '/home',
    component: () => import('@/views/home/index.vue'),
    meta: {hidden: true},
  },
  //系统
  {
    path: '/application/:applicationId/system',
    component: Layout,
    redirect: '/application/:applicationId/system/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/system/index.vue'),
        name: 'system',
        meta: {
          title: '系统',
          icon: 'system',
        },
      },
    ],
  },
  //用户
  {
    path: '/application/:applicationId/user',
    component: Layout,
    redirect: '/application/:applicationId/user/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/user/index.vue'),
        name: 'userList',
        meta: {
          title: '用户列表',
          icon: 'setting',
        },
      },
      {
        path: 'userRoleList/:userId',
        component: () => import('@/views/user/userRoleList.vue'),
        name: 'userRoleList',
        meta: {
          title: '用户角色分配',
          icon: 'setting',
        },
      },
      {
        path: 'userRoleAdd/:userId',
        component: () => import('@/views/user/userRoleAdd.vue'),
        name: 'userRoleAdd',
        meta: {
          title: '用户新增角色',
          icon: 'setting',
        },
      },
      {
        path: 'detail/:userId',
        component: () => import('@/views/user/detail.vue'),
        name: 'userDetail',
        meta: {
          title: '用户详情',
          icon: 'setting',
        },
      },
    ],
  },
  //应用
  {
    path: '/application/:applicationId/applications',
    component: Layout,
    redirect: '/application/:applicationId/applications/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/applications/index.vue'),
        name: 'applications',
        meta: {
          title: '应用',
          icon: 'guide',
        },
      },
    ],
  },
  //渠道
  {
    path: '/application/:applicationId/channel',
    component: Layout,
    redirect: '/application/:applicationId/channel/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/channel/index.vue'),
        name: 'channel',
        meta: {
          title: '渠道',
          icon: 'guide',
        },
      },
    ],
  },
  //商品管理
  {
    path: '/application/:applicationId/shop/:shopId/goods',
    component: Layout,
    redirect: '/application/:applicationId/shop/:shopId/goods/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/goods/index.vue'),
        name: 'goods',
        meta: {
          title: '商品管理',
          icon: 'setting',
        },
      },
    ],
  },
  //设置
  {
    path: '/application/:applicationId/setting',
    component: Layout,
    redirect: '/application/:applicationId/setting/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/setting/index.vue'),
        name: 'setting',
        meta: {
          title: '设置',
          icon: 'setting',
        },
      },
    ],
  },
  //订单
  {
    path: '/application/:applicationId/order',
    component: Layout,
    redirect: '/application/:applicationId/order/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/order/index.vue'),
        name: 'order',
        meta: {
          title: '订单',
          icon: 'order',
        },
      },
    ],
  },
  //营销活动
  {
    path: '/application/:applicationId/activity',
    component: Layout,
    redirect: '/application/:applicationId/activity/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/activity/index.vue'),
        name: 'activity',
        meta: {
          title: '营销',
          icon: 'marketing',
        },
      },
    ],
  },
  //配送
  {
    path: '/application/:applicationId/delivery',
    component: Layout,
    redirect: '/application/:applicationId/delivery/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/delivery/index.vue'),
        name: 'delivery',
        meta: {
          title: '配送',
          icon: 'rider',
        },
      },
    ],
  },
  //支付
  {
    path: '/application/:applicationId/pay',
    component: Layout,
    redirect: '/application/:applicationId/pay/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/pay/index.vue'),
        name: 'pay',
        meta: {
          title: '支付',
          icon: 'bill',
        },
      },
    ],
  },
  //商城
  {
    path: '/application/:applicationId/mall',
    component: Layout,
    redirect: '/application/:applicationId/mall/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/mall/index.vue'),
        name: 'mall',
        meta: {
          title: '商城',
          icon: 'bill',
        },
      },
    ],
  },
  //跑腿
  {
    path: '/application/:applicationId/agent',
    component: Layout,
    redirect: '/application/:applicationId/agent/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/agent/index.vue'),
        name: 'agent',
        meta: {
          title: '跑腿',
          icon: 'setting',
        },
      },
    ],
  },
  //模板管理
  {
    path: '/application/:applicationId/template',
    component: Layout,
    redirect: '/application/:applicationId/template/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/template/index.vue'),
        name: 'template',
        meta: {
          title: '装修',
          icon: 'setting',
        },
      },
    ],
  },
  //模板编辑
  {
    path: '/application/:applicationId/template/:templateId',
    component: () => import('@/views/template/components/microPage/index.vue'),
    meta: {hidden: true},
    name: 'microPage',
  },
  //装修
  {
    path: '/application/:applicationId/template/:templateId/pageConfig',
    component: () => import('@/views/template/components/decConfig/index.vue'),
    meta: {hidden: true},
    name: 'decConfig',
  },
  //二手
  {
    path: '/application/:applicationId/used',
    component: Layout,
    redirect: '/application/:applicationId/used/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/used/index.vue'),
        name: 'used',
        meta: {
          title: '二手',
          icon: 'setting',
        },
      },
    ],
  },
  //兼职
  {
    path: '/application/:applicationId/partTime',
    component: Layout,
    redirect: '/application/:applicationId/partTime/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/partTime/index.vue'),
        name: 'partTime',
        meta: {
          title: '兼职',
          icon: 'setting',
        },
      },
    ],
  },
  //统计
  {
    path: '/application/:applicationId/statistics',
    component: Layout,
    redirect: '/application/:applicationId/statistics/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/statistics/index.vue'),
        name: 'statistics',
        meta: {
          title: '统计',
          icon: 'setting',
        },
      },
    ],
  },
  //概况
  {
    path: '/application/:applicationId/overview',
    component: Layout,
    redirect: '/application/:applicationId/overview/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/overview/index.vue'),
        name: 'overview',
        meta: {
          title: '概况',
          icon: 'setting',
        },
      },
    ],
  },
  //服务
  {
    path: '/application/:applicationId/service',
    component: Layout,
    redirect: '/application/:applicationId/service/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/service/index.vue'),
        name: 'service',
        meta: {
          title: '服务',
          icon: 'setting',
        },
      },
    ],
  },
  //问卷
  {
    path: '/application/:applicationId/questionnaire',
    component: Layout,
    redirect: '/application/:applicationId/questionnaire/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/questionnaire/index.vue'),
        name: 'questionnaire',
        meta: {
          title: '问卷',
          icon: 'setting',
        },
      },
    ],
  },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export const asyncRoutes: RouteConfig[] = [];

const createRouter = () =>
  new Router({
    // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {x: 0, y: 0};
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
