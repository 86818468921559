
import {Component, Vue} from 'vue-property-decorator';
import {AppModule} from '@/store/modules/app';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
import Hamburger from '@/components/Hamburger/index.vue';
import {getUserId} from '../../../api/publicMethod';
import {users} from '../../../resources';
import {ObjectId} from 'bson';
@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
  },
})
export default class extends Vue {
  private role = localStorage.getItem('role');
  private userName = '';
  private userId: ObjectId = getUserId();
  async created() {
    try {
      //查询用户信息
      const user = (
        await users.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.userId),
            ),
          ),
        )
      ).find(() => true);
      this.userName = user?.spec.name ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  get sidebar(): {
    opened: boolean;
    withoutAnimation: boolean;
  } {
    return AppModule.sidebar;
  }
  get device(): string {
    return AppModule.device.toString();
  }
  private toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }
  //选择应用
  private selectApp() {
    this.$router.push('/home');
  }
  //退出登录
  private logout() {
    // localStorage.setItem('roleName', '');
    // localStorage.setItem('downloadUrl', '');
    // localStorage.setItem('refreshToken', '');
    // localStorage.setItem('accessToken', '');
    localStorage.clear();
    this.$router.push('/login');
  }
}
