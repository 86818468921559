/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'marketing': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M101.344 850.672h237.328V469.328L273.728 424 101.344 570.672zM394.672 850.672H632v-379.12L517.344 568l-122.672-66.672zM688 426.672v424h237.328V429.328L824.016 296z" p-id="6047"></path><path d="M140.544 464.544l129.072-107.968 246.576 150.112 292.416-275.392c1.056-0.992 1.92-2.144 2.816-3.248l49.248 49.808 79.312-223.2-227.52 73.296 54.448 55.072c-0.72 0.56-1.488 1.024-2.176 1.664L507.824 426.656 263.744 278.08 99.488 415.456c-13.568 11.344-15.36 31.52-4.016 45.072s31.536 15.376 45.072 4.016zM920 894.656H100c-17.664 0-32 14.336-32 32s14.336 32 32 32h820c17.664 0 32-14.336 32-32s-14.336-32-32-32z" p-id="6048"></path>'
  }
})
