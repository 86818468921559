/* eslint-disable */

import {ObjectId} from 'bson'

export namespace WechatPaySpecialMerchantImport {
  export const group: string = 'MaxCI';
  export const kind: string = 'WechatPaySpecialMerchantImport';
  export const version: string = 'v1';
 export const type: WechatPaySpecialMerchantImport = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 微信特约商户进件
 */
export interface WechatPaySpecialMerchantImport {
  spec: WechatPaySpecialMerchantImportSpec;
  status?: WechatPaySpecialMerchantImportStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface WechatPaySpecialMerchantImportSpec {
  /**
   * 超级管理员姓名
   */
  subjectType: "个体户" | "企业" | "事业单位" | "其他组织";
  /**
   * 商户名称
   */
  name?: string;
  contactInfo?: SpecialContactInfo;
  subjectInfo?: SubjectInfo;
  businessInfo?: BusinessInfo;
  accountInfo?: BankAccountInfo;
  additionInfo?: AdditionInfo;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 超级管理员信息
 */
export interface SpecialContactInfo {
  /**
   * 超级管理员姓名
   */
  contactName: string;
  /**
   * 超级管理员身份证件号码
   */
  contactIdCardNumber: string;
  /**
   * 超级管理员手机
   */
  mobilePhone: string;
  /**
   * 超级管理员邮箱
   */
  contactEmail: string;
}
/**
 * 主体资料
 */
export interface SubjectInfo {
  businessLicenseInfo?: SpecialBusinessLicenseInfo;
  certificateInfo?: CertificateInfo;
  organizationInfo?: SpecialOrganizationInfo;
  /**
   * 单位证明函照片
   */
  certificateLetterCopy?: string;
  /**
   * 经营者/法人身份证件
   */
  identityInfo: SpecialIdCardInfo | SpecialIdDocInfo;
  /**
   * 经营者/法人是否为受益人
   */
  owner: boolean;
  /**
   * 最终受益人信息
   */
  uboInfo?: UboIdCardInfo | UboIdDocInfo;
}
/**
 * 营业执照
 */
export interface SpecialBusinessLicenseInfo {
  /**
   * 证件扫描件
   */
  licenseCopy: string;
  /**
   * 证件注册号
   */
  licenseNumber: string;
  /**
   * 商户名称
   */
  merchantName: string;
  /**
   * 经营者/法定代表人姓名
   */
  legalPerson: string;
}
/**
 * 登记证书
 */
export interface CertificateInfo {
  /**
   * 证件扫描件
   */
  certCopy: string;
  /**
   * 登记证书类型
   */
  certType:
    | "事业单位法人证书"
    | "统一社会信用代码证书"
    | "有偿服务许可证"
    | "医疗机构执业许可证"
    | "企业营业执照"
    | "组织机构代码证"
    | "社会团体法人登记证书"
    | "民办非企业单位登记证书"
    | "基金会法人登记证书"
    | "慈善组织公开募捐资格证书"
    | "农民专业合作社法人营业执照"
    | "宗教活动场所登记证"
    | "其他证书";
  /**
   * 证书号
   */
  certNumber: string;
  /**
   * 商户名称
   */
  merchantName: string;
  /**
   * 注册地址
   */
  companyAddress: string;
  /**
   * 法人姓名
   */
  legalPerson: string;
  /**
   * 有效期限开始日期
   */
  periodBegin: Date;
  /**
   * 有效期限结束日期
   */
  periodEnd: Date | "长期";
  /**
   * 办学许可证
   */
  schoolLicensePic?: string;
}
/**
 * 组织机构代码证
 */
export interface SpecialOrganizationInfo {
  /**
   * 组织机构代码证照片
   */
  organizationCopy: string;
  /**
   * 组织机构代码
   */
  organizationCode: string;
  /**
   * 组织机构代码证有效期开始日期
   */
  orgPeriodBegin: Date;
  /**
   * 组织机构代码证有效期结束日期
   */
  orgPeriodEnd: Date | "长期";
}
/**
 * 经营者/法人身份证信息
 */
export interface SpecialIdCardInfo {
  /**
   * 经营者/法人证件类型
   */
  idDocType?: "身份证";
  /**
   * 身份证人像面照片
   */
  idCardCopy: string;
  /**
   * 身份证国徽面照片
   */
  idCardNational: string;
  /**
   * 身份证姓名
   */
  idCardName: string;
  /**
   * 身份证号码
   */
  idCardNumber: string;
  /**
   * 身份证有效期开始时间
   */
  cardPeriodBegin: Date;
  /**
   * 身份证有效期结束时间
   */
  cardPeriodEnd: Date | "长期";
}
/**
 * 经营者/法人其他类型证件信息
 */
export interface SpecialIdDocInfo {
  /**
   * 经营者/法人证件类型
   */
  idDocType?: "护照" | "中国香港居民来往内地通行证" | "中国澳门居民来往内地通行证" | "中国台湾居民来往大陆通行证";
  /**
   * 证件姓名
   */
  idDocName: string;
  /**
   * 证件号码
   */
  idDocNumber: string;
  /**
   * 证件照片
   */
  idDocCopy: string;
  /**
   * 证件有效期开始时间
   */
  docPeriodBegin: Date;
  /**
   * 证件有效期结束时间
   */
  docPeriodEnd: Date | "长期";
}
/**
 * 最终受益人身份证
 */
export interface UboIdCardInfo {
  /**
   * 经营者/法人证件类型
   */
  idType?: "身份证";
  /**
   * 身份证人像面照片
   */
  idCardCopy: string;
  /**
   * 身份证国徽面照片
   */
  idCardNational: string;
  /**
   * 受益人姓名
   */
  name: string;
  /**
   * 证件号码
   */
  idNumber: string;
  /**
   * 证件有效期开始时间
   */
  idPeriodBegin: Date;
  /**
   * 证件有效期结束时间
   */
  idPeriodEnd: Date | "长期";
}
/**
 * 最终受益人证件
 */
export interface UboIdDocInfo {
  /**
   * 经营者/法人证件类型
   */
  idType?: "护照" | "中国香港居民来往内地通行证" | "中国澳门居民来往内地通行证" | "中国台湾居民来往大陆通行证";
  /**
   * 证件照片
   */
  idDocCopy: string;
  /**
   * 受益人姓名
   */
  name: string;
  /**
   * 证件号码
   */
  idNumber: string;
  /**
   * 证件有效期开始时间
   */
  idPeriodBegin: Date;
  /**
   * 证件有效期结束时间
   */
  idPeriodEnd: Date | "长期";
}
/**
 * 经营资料
 */
export interface BusinessInfo {
  /**
   * 商户简称
   */
  merchantShortname: string;
  /**
   * 客服电话
   */
  servicePhone: string;
}
/**
 * 结算银行账户
 */
export interface BankAccountInfo {
  /**
   * 账户类型
   */
  accountType: "对公银行账户" | "经营者个人银行卡";
  /**
   * 开户名称
   */
  accountName: string;
  /**
   * 开户银行
   */
  accountBank: string;
  /**
   * 开户银行省市编码
   */
  bankAddressCode: string;
  /**
   * 开户银行联行号
   */
  bankBranchId?: string;
  /**
   * 开户银行全称 （含支行）
   */
  bankName?: string;
  /**
   * 银行帐号
   */
  accountNumber: string;
}
/**
 * 补充材料
 */
export interface AdditionInfo {
  /**
   * 法人开户承诺函
   */
  legalPersonCommitment?: string;
  /**
   * 法人开户意愿视频
   */
  legalPersonVideo?: string;
  /**
   * 补充材料
   */
  businessAdditionPics?: string[];
  /**
   * 补充说明
   */
  businessAdditionMsg?: string;
}
/**
 * 状态
 */
export interface WechatPaySpecialMerchantImportStatus {
  /**
   * 当前阶段
   */
  phase?: "已提交" | "编辑中" | "审核中" | "待签约" | "待账户验证" | "开通权限中" | "完成" | "已驳回" | "作废" | "撤销";
  /**
   * 微信支付申请单号
   */
  applymentId?: number;
  /**
   * 超级管理员签约链接
   */
  signUrl?: string;
  /**
   * 电商平台二级商户号
   */
  subMchid?: string;
  /**
   * 驳回原因详情
   */
  auditDetail?: {
    [k: string]: unknown;
  }[];
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
