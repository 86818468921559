/* eslint-disable */

import {ObjectId} from 'bson'

export namespace AfterSaleOrder {
  export const group: string = 'MaxCI';
  export const kind: string = 'AfterSaleOrder';
  export const version: string = 'v1';
 export const type: AfterSaleOrder = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 售后订单
 */
export interface AfterSaleOrder {
  spec: AfterSaleOrderSpec;
  status?: AfterSaleOrderStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface AfterSaleOrderSpec {
  /**
   * 商品列表
   */
  items: AfterSaleOrderItem[];
  /**
   * 退款原因
   */
  reason: string;
  /**
   * 拍照凭证
   */
  credentials?: [] | [string] | [string, string] | [string, string, string];
  amount?: MallOrderAmount;
  /**
   * 备注
   */
  remarks?: string;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
}
/**
 * 退款商品
 */
export interface AfterSaleOrderItem {
  /**
   * 金额
   */
  amount: number;
  /**
   * 名称
   */
  name?: string;
  /**
   * 商品图片(最多5张)
   */
  images?:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string];
  /**
   * 商品库存单位
   */
  sku: string;
  /**
   * 属性
   */
  attributes: string[];
  /**
   * 数量
   */
  count: number;
  /**
   * 产品ID
   */
  productId: ObjectId;
}
/**
 * 退款金额
 */
export interface MallOrderAmount {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 满减金额
 */
export interface MallDiscount {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 优惠券金额
 */
export interface MallDiscount1 {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 状态
 */
export interface AfterSaleOrderStatus {
  /**
   * 当前阶段
   */
  phase?: "申请" | "通过" | "驳回" | "取消" | "完成" | "无效";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
