/* eslint-disable */

import {ObjectId} from 'bson'

export namespace UserIntegral {
  export const group: string = 'MaxCI';
  export const kind: string = 'UserIntegral';
  export const version: string = 'v1';
 export const type: UserIntegral = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 用户积分交易
 */
export interface UserIntegral {
  /**
   * 规格
   */
  spec: UserIntegralIncomeSpec | UserIntegralSpendSpec;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
  status?: ResourceStatus;
}
/**
 * 用户积分交易收入规格
 */
export interface UserIntegralIncomeSpec {
  /**
   * 收支类型
   */
  direction: "收入";
  /**
   * 来源
   */
  source: OrderIntegralSource | AdjustIntegralSource;
  /**
   * 积分
   */
  integral: number;
  /**
   * 备注
   */
  remarks: string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 订单积分
 */
export interface OrderIntegralSource {
  /**
   * 类型
   */
  type: "商城";
  /**
   * 订单
   */
  orderId: ObjectId;
}
/**
 * 调整积分
 */
export interface AdjustIntegralSource {
  /**
   * 类型
   */
  type: "调整";
  /**
   * 请求ID
   */
  requestId: ObjectId;
}
/**
 * 用户积分交易支出规格
 */
export interface UserIntegralSpendSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 来源
   */
  source: OrderIntegralSource | AdjustIntegralSource | AfterSaleSource;
  /**
   * 积分
   */
  integral: number;
  /**
   * 备注
   */
  remarks: string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 售后
 */
export interface AfterSaleSource {
  /**
   * 类型
   */
  type: "售后";
  /**
   * 售后单ID
   */
  afterSaleOrderId: ObjectId;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
/**
 * 状态
 */
export interface ResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: string;
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
