/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'school': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M743.7 475.5v395.2c0 6-4.9 10.9-10.9 10.9h-24.5c-6 0-10.9-4.9-10.9-10.9V290.1c0-6 4.9-10.9 10.9-10.9h45.6c10.8 0 15.1-14 6.1-20.1L518.1 97.8c-3.7-2.5-8.5-2.5-12.1 0L264 259.1c-9 6-4.8 20.1 6.1 20.1h45.6c6 0 10.9 4.9 10.9 10.9v580.6c0 6-4.9 10.9-10.9 10.9h-24.4c-6 0-10.9-4.9-10.9-10.9V475.5c0-6-4.9-10.9-10.9-10.9H105.8c-6 0-10.9 4.9-10.9 10.9v441.6c0 6 4.9 10.9 10.9 10.9h812.3c6 0 10.9-4.9 10.9-10.9V475.5c0-6-4.9-10.9-10.9-10.9H754.7c-6.1 0-11 4.9-11 10.9z m-591.5 81.7H223c6 0 10.9 4.9 10.9 10.9v24.5c0 6-4.9 10.9-10.9 10.9h-70.8c-6 0-10.9-4.9-10.9-10.9v-24.5c-0.1-6 4.8-10.9 10.9-10.9z m0 92.7H223c6 0 10.9 4.9 10.9 10.9v24.4c0 6-4.9 10.9-10.9 10.9h-70.8c-6 0-10.9-4.9-10.9-10.9v-24.4c-0.1-6 4.8-10.9 10.9-10.9z m0 92.7H223c6 0 10.9 4.9 10.9 10.9V778c0 6-4.9 10.9-10.9 10.9h-70.8c-6 0-10.9-4.9-10.9-10.9v-24.4c-0.1-6.1 4.8-11 10.9-11zM512 325.5c72.5 0 125.5 83.1 68.9 159.3-0.6 0.9-1.4 1.6-2.3 2.3-76.2 56.6-159.3 3.6-159.3-68.9 0-51.2 41.5-92.7 92.7-92.7z m-92.7 545.2V660.9c0-6 4.9-10.9 10.9-10.9h163.5c6 0 10.9 4.9 10.9 10.9v209.8c0 6-4.9 10.9-10.9 10.9H430.3c-6.1 0.1-11-4.8-11-10.9zM801 557.2h70.8c6 0 10.9 4.9 10.9 10.9v24.5c0 6-4.9 10.9-10.9 10.9H801c-6 0-10.9-4.9-10.9-10.9v-24.5c0-6 4.9-10.9 10.9-10.9z m0 92.7h70.8c6 0 10.9 4.9 10.9 10.9v24.4c0 6-4.9 10.9-10.9 10.9H801c-6 0-10.9-4.9-10.9-10.9v-24.4c0-6 4.9-10.9 10.9-10.9z m0 92.7h70.8c6 0 10.9 4.9 10.9 10.9V778c0 6-4.9 10.9-10.9 10.9H801c-6 0-10.9-4.9-10.9-10.9v-24.4c0-6.1 4.9-11 10.9-11z m-10.9 0" p-id="15116"></path>'
  }
})
