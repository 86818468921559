/* eslint-disable */

import {ObjectId} from 'bson'

export namespace WechatPayMerchantImport {
  export const group: string = 'MaxCI';
  export const kind: string = 'WechatPayMerchantImport';
  export const version: string = 'v1';
 export const type: WechatPayMerchantImport = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 电商平台二级商户进件
 */
export interface WechatPayMerchantImport {
  /**
   * 规格
   */
  spec: WechatPayMerchantBusinessSpec | WechatPayMerchantMicroSpec;
  status?: WechatPayMerchantImportStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 企业规格
 */
export interface WechatPayMerchantBusinessSpec {
  /**
   * 商户名称
   */
  name: string;
  /**
   * 主体类型
   */
  organizationType: "个体工商户" | "企业" | "事业单位" | "其他组织";
  businessLicenseInfo?: BusinessLicenseInfo;
  organizationCertInfo?: OrganizationCertInfo;
  common?: WechatPayMerchantCommon;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 营业执照/登记证书信息
 */
export interface BusinessLicenseInfo {
  /**
   * 证件扫描件
   */
  businessLicenseCopy: string;
  /**
   * 证件注册号
   */
  businessLicenseNumber: string;
  /**
   * 商户名称
   */
  merchantName: string;
  /**
   * 经营者/法定代表人姓名
   */
  legalPerson: string;
  /**
   * 注册地址
   */
  companyAddress: string;
  /**
   * 营业期限
   */
  businessTime: [Date, Date | "长期"];
}
/**
 * 组织机构代码证信息
 */
export interface OrganizationCertInfo {
  /**
   * 组织机构代码证照片
   */
  organizationCopy: string;
  /**
   * 组织机构代码
   */
  organizationNumber: string;
  /**
   * 组织机构代码有效期限
   */
  organizationTime: [Date, Date | "长期"];
}
/**
 * 商户公共信息
 */
export interface WechatPayMerchantCommon {
  /**
   * 身份信息
   */
  idInfo?: IdCardInfo | IdDocInfo;
  contactInfo?: ContactInfo;
  salesSceneInfo?: SalesSceneInfo;
  /**
   * 商户简称
   */
  merchantShortname?: string;
  /**
   * 特殊资质
   */
  qualifications?: string[];
  /**
   * 补充材料
   */
  businessAdditionPics?: string[];
  /**
   * 补充说明
   */
  businessAdditionDesc?: string;
}
/**
 * 经营者/法人身份证信息
 */
export interface IdCardInfo {
  /**
   * 经营者/法人证件类型
   */
  idDocType?: "身份证";
  /**
   * 身份证人像面照片
   */
  idCardCopy: string;
  /**
   * 身份证国徽面照片
   */
  idCardNational: string;
  /**
   * 身份证姓名
   */
  idCardName: string;
  /**
   * 身份证号码
   */
  idCardNumber: string;
  /**
   * 身份证有效期限
   */
  idCardValidTime: [Date, Date | "长期"];
}
/**
 * 经营者/法人其他类型证件信息
 */
export interface IdDocInfo {
  /**
   * 经营者/法人证件类型
   */
  idDocType?: "护照" | "中国香港居民来往内地通行证" | "中国澳门居民来往内地通行证" | "中国台湾居民来往大陆通行证";
  /**
   * 证件姓名
   */
  idDocName: string;
  /**
   * 证件号码
   */
  idDocNumber: string;
  /**
   * 证件照片
   */
  idDocCopy: string;
  /**
   * 证件结束日期
   */
  docPeriodEnd: string;
}
/**
 * 超级管理员信息
 */
export interface ContactInfo {
  /**
   * 超级管理员类型
   */
  contactType: "法人" | "负责人";
  /**
   * 超级管理员姓名
   */
  contactName: string;
  /**
   * 超级管理员身份证件号码
   */
  contactIdCardNumber: string;
  /**
   * 超级管理员手机
   */
  mobilePhone: string;
  /**
   * 超级管理员邮箱
   */
  contactEmail: string;
}
/**
 * 店铺信息
 */
export interface SalesSceneInfo {
  /**
   * 店铺名称
   */
  storeName: string;
  /**
   * 店铺链接
   */
  storeUrl?: string;
  /**
   * 店铺二维码
   */
  storeQrCode?: string;
}
/**
 * 小微商户/个人卖家
 */
export interface WechatPayMerchantMicroSpec {
  /**
   * 商户名称
   */
  name: string;
  /**
   * 主体类型
   */
  organizationType: "小微商户" | "个人卖家";
  common?: WechatPayMerchantCommon1;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 商户公共信息
 */
export interface WechatPayMerchantCommon1 {
  /**
   * 身份信息
   */
  idInfo?: IdCardInfo | IdDocInfo;
  contactInfo?: ContactInfo;
  salesSceneInfo?: SalesSceneInfo;
  /**
   * 商户简称
   */
  merchantShortname?: string;
  /**
   * 特殊资质
   */
  qualifications?: string[];
  /**
   * 补充材料
   */
  businessAdditionPics?: string[];
  /**
   * 补充说明
   */
  businessAdditionDesc?: string;
}
/**
 * 状态
 */
export interface WechatPayMerchantImportStatus {
  /**
   * 当前阶段
   */
  phase?: "已提交" | "资料校验中" | "审核中" | "待签约" | "待账户验证" | "已冻结" | "完成" | "已驳回" | "撤销";
  /**
   * 微信支付申请单号
   */
  applymentId?: number;
  /**
   * 签约链接
   */
  signUrl?: string;
  accountValidation?: WechatPayImportAccountValidation;
  /**
   * 法人验证链接
   */
  legalValidationUrl?: string;
  /**
   * 电商平台二级商户号
   */
  subMchid?: string;
  /**
   * 驳回原因详情
   */
  auditDetail?: {
    [k: string]: unknown;
  }[];
  conditions: Condition[];
}
/**
 * 汇款账户验证信息
 */
export interface WechatPayImportAccountValidation {
  /**
   * 付款户名
   */
  accountName: string;
  /**
   * 付款卡号
   */
  accountNo?: string;
  /**
   * 汇款金额
   */
  payAmount: number;
  /**
   * 收款卡号
   */
  destinationAccountNumber: string;
  /**
   * 收款户名
   */
  destinationAccountName: string;
  /**
   * 开户银行
   */
  destinationAccountBank: string;
  /**
   * 省市信息
   */
  city: string;
  /**
   * 备注信息
   */
  remark: string;
  /**
   * 汇款截止时间
   */
  deadline: string;
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
