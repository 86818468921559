/* eslint-disable */

import {ObjectId} from 'bson'

export namespace RiderInfo {
  export const group: string = 'MaxCI';
  export const kind: string = 'RiderInfo';
  export const version: string = 'v1';
 export const type: RiderInfo = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 骑手信息
 */
export interface RiderInfo {
  spec: RiderInfoSpec;
  status?: RiderInfoStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface RiderInfoSpec {
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 地理位置
   */
  geometry?: {
    [k: string]: unknown;
  };
  /**
   * 是否工作
   */
  working: boolean;
  /**
   * 类型
   */
  type: "骑手" | "分拣员";
  /**
   * 真实姓名
   */
  name: string;
  /**
   * 身份证号码
   */
  cardId: string;
  /**
   * 身份证正面照片
   */
  cardPositive: string;
  /**
   * 身份证反面照片
   */
  cardNegative: string;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
}
/**
 * 状态
 */
export interface RiderInfoStatus {
  /**
   * 当前阶段
   */
  phase?: "就绪" | "禁用";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
