/* eslint-disable */

import {ObjectId} from 'bson'

export namespace ApplicationRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'ApplicationRequest';
  export const version: string = 'v1';
 export const type: ApplicationRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 应用请求
 */
export interface ApplicationRequest {
  /**
   * 规格
   */
  spec: ApplicationUpdateRequestSpec | ChannelCreateRequestSpec;
  status?: RequestResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 更新应用规格
 */
export interface ApplicationUpdateRequestSpec {
  /**
   * 类型
   */
  type: "更新应用";
  /**
   * 名称
   */
  name?: string;
  /**
   * 头像
   */
  logo?: string;
  /**
   * 简介
   */
  description?: string;
  /**
   * 启用服务
   */
  enableServices?: ("商城" | "配送" | "跑腿" | "二手" | "会员卡")[];
  serviceConfigs?: ApplicationServiceConfig;
  config?: ApplicationConfig;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 服务
 */
export interface ApplicationServiceConfig {
  商城?: ApplicationMallServiceConfig;
  配送?: DeliveryConfig;
  跑腿?: AgentConfig;
  二手?: UsedConfig;
  会员卡?: MemberCardConfig;
}
/**
 * 商城服务配置
 */
export interface ApplicationMallServiceConfig {
  /**
   * 统计模板
   */
  statistics: StatisticsTemplate[];
  /**
   * 打印取消订单
   */
  printCancel: boolean;
  /**
   * 打印logo
   */
  printLogo: string;
}
/**
 * 统计模板字段
 */
export interface StatisticsTemplate {
  /**
   * 名称
   */
  name: string;
  /**
   * 模板
   */
  tepmlate: string;
  /**
   * 颜色
   */
  color: string;
  /**
   * 简介
   */
  description: string;
}
/**
 * 配送服务配置
 */
export interface DeliveryConfig {
  shopFee: DeliveryFee;
  riderFee: DeliveryFee1;
}
/**
 * 店铺配送费（单位：分）
 */
export interface DeliveryFee {
  /**
   * 基础费用
   */
  baseFee: number;
  /**
   * 送货上门费用
   */
  toHomeFee: number;
}
/**
 * 骑手配送费（单位：分）
 */
export interface DeliveryFee1 {
  /**
   * 基础费用
   */
  baseFee: number;
  /**
   * 送货上门费用
   */
  toHomeFee: number;
}
/**
 * 跑腿服务配置
 */
export interface AgentConfig {
  /**
   * 支付商户
   */
  merchantId: string;
  /**
   * 服务费率
   */
  serviceRate: number;
  /**
   * 服务费保底（单位：分）
   */
  serviceGuaranteed: number;
  guideFee: AgentFee;
  minimumFee: AgentFee1;
}
/**
 * 跑腿指导价（单位：分）
 */
export interface AgentFee {
  /**
   * 帮忙价（单位：分）
   */
  帮忙: number;
  /**
   * 代取价（单位：分）
   */
  代取: number;
  /**
   * 代送价（单位：分）
   */
  代送: number;
  /**
   * 代购价（单位：分）
   */
  代购: number;
}
/**
 * 指导最低价（单位：分）
 */
export interface AgentFee1 {
  /**
   * 帮忙价（单位：分）
   */
  帮忙: number;
  /**
   * 代取价（单位：分）
   */
  代取: number;
  /**
   * 代送价（单位：分）
   */
  代送: number;
  /**
   * 代购价（单位：分）
   */
  代购: number;
}
/**
 * 二手服务配置
 */
export interface UsedConfig {
  /**
   * 支付商户
   */
  merchantId: string;
  /**
   * 服务费率
   */
  serviceRate: number;
}
/**
 * 会员卡服务配置
 */
export interface MemberCardConfig {
  /**
   * 支付商户
   */
  merchantId: string;
}
/**
 * 应用配置
 */
export interface ApplicationConfig {
  /**
   * 最低提现金额
   */
  minimumWithdrawal?: number;
  /**
   * 客服配置
   */
  customerConfig?: Customer[];
  lushpay?: LushpayConfig;
  /**
   * 结算周期(天)
   */
  settlementCycle?: number;
}
/**
 * 客服
 */
export interface Customer {
  /**
   * 姓名
   */
  name: string;
  /**
   * 电话
   */
  phone: string;
}
/**
 * 青山支付配置
 */
export interface LushpayConfig {
  /**
   * 服务商ID
   */
  partnerId: string;
  /**
   * 服务商密钥
   */
  secret: string;
}
/**
 * 创建渠道请求
 */
export interface ChannelCreateRequestSpec {
  /**
   * 类型
   */
  type: "创建渠道";
  /**
   * 渠道名称
   */
  name: string;
  /**
   * 渠道类型
   */
  channelType: "微信小程序" | "微信公众号" | "移动应用" | "网站" | "支付宝小程序";
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface RequestResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
