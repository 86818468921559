
import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({
  name: 'Hamburger',
})
export default class extends Vue {
  @Prop({default: false}) private isActive!: boolean;
  private toggleClick() {
    this.$emit('toggleClick');
  }
}
