/* eslint-disable */

import {ObjectId} from 'bson'

export namespace DeliveryOrder {
  export const group: string = 'MaxCI';
  export const kind: string = 'DeliveryOrder';
  export const version: string = 'v1';
 export const type: DeliveryOrder = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 收货人地理位置
 */
export type GeometrySchema = Point | Polygon | MultiPolygon;
/**
 * 地理位置范围
 */
export type GeometrySchema1 = Point | Polygon | MultiPolygon;

/**
 * 配送订单
 */
export interface DeliveryOrder {
  spec: InstantDeliveryOrderSpec;
  status?: DeliveryOrderStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface InstantDeliveryOrderSpec {
  /**
   * 类型
   */
  type: "即时配送";
  /**
   * 配送门店ID
   */
  shopId: ObjectId;
  /**
   * 第三方订单号
   */
  outTradeNo: string;
  /**
   * 第三方订单标识号
   */
  outMarkNo?: string;
  /**
   * 订单金额（单位：分）
   */
  price: number;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 回调URL
   */
  callback: string;
  /**
   * 商品明细
   */
  productInfo: DeliveryProductInfo[];
  receiverInfo?: ReceiverInfo;
  /**
   * 分拣员ID
   */
  sorterId?: ObjectId;
  sorterInfo?: DeliveryUserInfo;
  /**
   * 配送员ID
   */
  riderId?: ObjectId;
  riderInfo?: DeliveryUserInfo1;
  shopInfo?: DeliveryShopInfo;
  shopFee?: DeliveryFee;
  group?: Group;
  riderFee?: DeliveryFee1;
  /**
   * 期望时间
   */
  expectTime?: Date;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 商品信息
 */
export interface DeliveryProductInfo {
  /**
   * 名称
   */
  name: string;
  /**
   * 数量
   */
  count: number;
  /**
   * 价格
   */
  price: number;
}
/**
 * 收货人信息
 */
export interface ReceiverInfo {
  /**
   * 收货人姓名
   */
  name: string;
  /**
   * 收货人电话
   */
  phone: string;
  /**
   * 收货人地址
   */
  address: string;
  geometry: GeometrySchema;
}
/**
 * 地理位置-点
 */
export interface Point {
  /**
   * 类型
   */
  type: "Point";
  /**
   * 坐标数据
   */
  coordinates: [number, number];
}
/**
 * 地理位置-多边形
 */
export interface Polygon {
  /**
   * 类型
   */
  type: "Polygon";
  /**
   * 坐标数据
   */
  coordinates: [number, number][][];
}
/**
 * 地理位置-多多边形
 */
export interface MultiPolygon {
  /**
   * 请求类型
   */
  type: "MultiPolygon";
  /**
   * 请求类型
   */
  coordinates: [number, number][][][];
}
/**
 * 分拣员信息
 */
export interface DeliveryUserInfo {
  /**
   * 姓名
   */
  name: string;
  /**
   * 手机号
   */
  phone: string;
}
/**
 * 配送员信息
 */
export interface DeliveryUserInfo1 {
  /**
   * 姓名
   */
  name: string;
  /**
   * 手机号
   */
  phone: string;
}
/**
 * 配送门店信息
 */
export interface DeliveryShopInfo {
  /**
   * 店铺名称
   */
  name: string;
  /**
   * 店铺地址
   */
  address: string;
  /**
   * 联系人
   */
  contactName: string;
  /**
   * 联系电话
   */
  phone: string;
  geometry: GeometrySchema1;
}
/**
 * 门店配送费
 */
export interface DeliveryFee {
  /**
   * 基础费用
   */
  baseFee: number;
  /**
   * 送货上门费用
   */
  toHomeFee: number;
}
/**
 * 分组
 */
export interface Group {
  /**
   * 分组ID
   */
  id: ObjectId;
  /**
   * 分组名称
   */
  name: string;
}
/**
 * 骑手配送费
 */
export interface DeliveryFee1 {
  /**
   * 基础费用
   */
  baseFee: number;
  /**
   * 送货上门费用
   */
  toHomeFee: number;
}
/**
 * 状态
 */
export interface DeliveryOrderStatus {
  /**
   * 当前阶段
   */
  phase?: "下单" | "接单" | "分拣" | "到店" | "取货" | "完成" | "无效" | "取消";
  /**
   * 语音通知信息
   */
  notice?: "正在呼叫" | "成功" | "用户占线" | "拒绝" | "关机" | "停机" | "不在服务区" | "空号" | "呼叫受限" | "异常";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
