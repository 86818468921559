/* eslint-disable */

import {ObjectId} from 'bson'

export namespace UserTransaction {
  export const group: string = 'MaxCI';
  export const kind: string = 'UserTransaction';
  export const version: string = 'v1';
 export const type: UserTransaction = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 用户交易
 */
export interface UserTransaction {
  /**
   * 规格
   */
  spec: UserIncomeSpec | UserWithdrawalSpec;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
  status?: ResourceStatus;
}
/**
 * 用户收入规格
 */
export interface UserIncomeSpec {
  /**
   * 收支类型
   */
  direction: "收入";
  /**
   * 订单类型
   */
  orderType: "跑腿" | "二手";
  /**
   * 金额
   */
  amount: number;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 用户提现规格
 */
export interface UserWithdrawalSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 订单类型
   */
  orderType: "提现";
  /**
   * 金额
   */
  amount: number;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
/**
 * 状态
 */
export interface ResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: string;
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
