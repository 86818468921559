
import {Component, Vue} from 'vue-property-decorator';
import {SettingsModule} from '@/store/modules/settings';
import ThemePicker from '@/components/ThemePicker/index.vue';
@Component({
  name: 'Settings',
  components: {
    ThemePicker,
  },
})
export default class extends Vue {
  get sidebarTextTheme() {
    return SettingsModule.sidebarTextTheme;
  }
  set sidebarTextTheme(value) {
    SettingsModule.ChangeSetting({key: 'sidebarTextTheme', value});
  }
  private themeChange(value: string) {
    SettingsModule.ChangeSetting({key: 'theme', value});
  }
}
