import {
  VuexModule,
  Mutation,
  Action,
  getModule,
  Module,
} from 'vuex-module-decorators';
import store from '@/store';
import elementVariables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';

export interface SettingsState {
  theme: string;
  showSettings: boolean;
  sidebarTextTheme: boolean;
}

@Module({dynamic: true, store, name: 'settings'})
class Settings extends VuexModule implements SettingsState {
  public theme = elementVariables.theme;
  public showSettings = defaultSettings.showSettings;
  public sidebarTextTheme = defaultSettings.sidebarTextTheme;

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private CHANGE_SETTING(payload: {key: string; value: any}) {
    const {key, value} = payload;
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)[key] = value;
    }
  }

  @Action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ChangeSetting(payload: {key: string; value: any}) {
    this.CHANGE_SETTING(payload);
  }
}

export const SettingsModule = getModule(Settings);
