import {createClient} from '@warehouse/client';
import {webSocketAdapter} from '@warehouse/client-browser';
import {PlatformConfig} from './externals/MaxCI-PlatformConfig-v1';
import {WechatLoginRequest} from './externals/MaxCI-WechatLoginRequest-v1';
import {PhoneCodeRequest} from './externals/MaxCI-PhoneCodeRequest-v1';
import {PhoneLoginRequest} from './externals/MaxCI-PhoneLoginRequest-v1';
import {AccessTokenRequest} from './externals/MaxCI-AccessTokenRequest-v1';
import {Application} from './externals/MaxCI-Application-v1';
import {RoleMapping} from './externals/MaxCI-RoleMapping-v1';
import {Role} from './externals/Core-Role-v1';
import {Permission} from './externals/Core-Permission-v1';
import {ApplicationCreateRequest} from './externals/MaxCI-ApplicationCreateRequest-v1';
import {ApplicationRequest} from './externals/MaxCI-ApplicationRequest-v1';
import {ChannelRequest} from './externals/MaxCI-ChannelRequest-v1';
import {Channel} from './externals/MaxCI-Channel-v1';
import {WechatTemplate} from './externals/MaxCI-WechatTemplate-v1';
import {WechatTemplateDraft} from './externals/MaxCI-WechatTemplateDraft-v1';
import {User} from './externals/MaxCI-User-v1';
import {WechatCodeRequest} from './externals/MaxCI-WechatCodeRequest-v1';
import {PageLabel} from './externals/MaxCI-PageLabel-v1';
import {Page} from './externals/MaxCI-Page-v1';
import {UploadRequest} from './externals/MaxCI-UploadRequest-v1';
import {DownloadRequest} from './externals/MaxCI-DownloadRequest-v1';
import {ShopLabel} from './externals/MaxCI-ShopLabel-v1';
import {Shop} from './externals/MaxCI-Shop-v1';
import {ProductLabel} from './externals/MaxCI-ProductLabel-v1';
import {Product} from './externals/MaxCI-Product-v1';
import {PayMerchant} from './externals/MaxCI-PayMerchant-v1';
import {WechatPayMerchantImport} from './externals/MaxCI-WechatPayMerchantImport-v1';
import {WechatPayMerchantImportRequest} from './externals/MaxCI-WechatPayMerchantImportRequest-v1';
import {CouponActivity} from './externals/MaxCI-CouponActivity-v1';
import {Coupon} from './externals/MaxCI-Coupon-v1';
import {WechatPayMerchatRequest} from './externals/MaxCI-WechatPayMerchatRequest-v1';
import {DeliveryMerchant} from './externals/MaxCI-DeliveryMerchant-v1';
import {PrintTemplate} from './externals/MaxCI-PrintTemplate-v1';
import {Order} from './externals/MaxCI-Order-v1';
import {RiderExamineRequest} from './externals/MaxCI-RiderExamineRequest-v1';
import {RiderSignupRequest} from './externals/MaxCI-RiderSignupRequest-v1';
import {ApplicationOrderRequest} from './externals/MaxCI-ApplicationOrderRequest-v1';
import {DeliveryOrderGroupLabel} from './externals/MaxCI-DeliveryOrderGroupLabel-v1';
import {DeliveryOrderGroup} from './externals/MaxCI-DeliveryOrderGroup-v1';
import {DeliveryShop} from './externals/MaxCI-DeliveryShop-v1';
import {DeliveryShopLabel} from './externals/MaxCI-DeliveryShopLabel-v1';
import {RiderInfo} from './externals/MaxCI-RiderInfo-v1';
import {DeliveryDeveloper} from './externals/MaxCI-DeliveryDeveloper-v1';
import {PayTransaction} from './externals/MaxCI-PayTransaction-v1';
import {WechatPaySpecialMerchantImport} from './externals/MaxCI-WechatPaySpecialMerchantImport-v1';
import {WechatPaySpecialMerchantImportRequest} from './externals/MaxCI-WechatPaySpecialMerchantImportRequest-v1';
import {DeliveryOrder} from './externals/MaxCI-DeliveryOrder-v1';
import {Config} from './externals/MaxCI-Config-v1';
import {UITemplate} from './externals/MaxCI-UITemplate-v1';
import {SharedTemplate} from './externals/MaxCI-SharedTemplate-v1';
import {SharedTemplateRequest} from './externals/MaxCI-SharedTemplateRequest-v1';
import {UsedOrderRequest} from './externals/MaxCI-UsedOrderRequest-v1';
import {UsedProduct} from './externals/MaxCI-UsedProduct-v1';
import {UserView} from './externals/MaxCI-UserView-v1';
import {UsedProductLabel} from './externals/MaxCI-UsedProductLabel-v1';
import {SignUp} from './externals/MaxCI-SignUp-v1';
import {AfterSaleOrder} from './externals/MaxCI-AfterSaleOrder-v1';
import {AfterSaleOrderRequest} from './externals/MaxCI-AfterSaleOrderRequest-v1';
import {ShopOrderRequest} from './externals/MaxCI-ShopOrderRequest-v1';
import {ShopRequest} from './externals/MaxCI-ShopRequest-v1';
import {MemberCard} from './externals/MaxCI-MemberCard-v1';
import {Recharge} from './externals/MaxCI-Recharge-v1';
import {UserIntegral} from './externals/MaxCI-UserIntegral-v1';
import {UserTransaction} from './externals/MaxCI-UserTransaction-v1';
import {UserIntegralRequest} from './externals/MaxCI-UserIntegralRequest-v1';
import {UserRequest} from './externals/MaxCI-UserRequest-v1';
import {CouponDistributeRequest} from './externals/MaxCI-CouponDistributeRequest-v1';
import {GroupRiderExamineRequest} from './externals/MaxCI-GroupRiderExamineRequest-v1';
import {GroupRiderSignupRequest} from './externals/MaxCI-GroupRiderSignupRequest-v1';
import {GroupRiderInfo} from './externals/MaxCI-GroupRiderInfo-v1';
import {GroupDeliveryPoint} from './externals/MaxCI-GroupDeliveryPoint-v1';
import {GroupDeliveryPointLabel} from './externals/MaxCI-GroupDeliveryPointLabel-v1';
import {Question} from './externals/MaxCI-Question-v1';
import {QuestionLabel} from './externals/MaxCI-QuestionLabel-v1';
import {TestPaper} from './externals/MaxCI-TestPaper-v1';
import {Student} from './externals/MaxCI-Student-v1';
import {AnswerRecord} from './externals/MaxCI-AnswerRecord-v1';
import {DeliveryShopRequest} from './externals/MaxCI-DeliveryShopRequest-v1';
import {DeliveryOrderApplicationRequest} from './externals/MaxCI-DeliveryOrderApplicationRequest-v1';
import {WechatUser} from './externals/MaxCI-WechatUser-v1';
import {WechatACodeRequest} from './externals/MaxCI-WechatACodeRequest-v1';
import {ChannelApp} from './externals/MaxCI-ChannelApp-v1';
import {ClientVersion} from './externals/MaxCI-ClientVersion-v1';
import {Printer} from './externals/MaxCI-Printer-v1';
import {PrinterRequest} from './externals/MaxCI-PrinterRequest-v1';
import {RoleDeleteRequest} from './externals/MaxCI-RoleDeleteRequest-v1';
import {MallCustomer} from './externals/MaxCI-MallCustomer-v1';
import {MallCustomerRequest} from './externals/MaxCI-MallCustomerRequest-v1';
import {PrinterDistributeRequest} from './externals/MaxCI-PrinterDistributeRequest-v1';
import {AlipayACodeRequest} from './externals/MaxCI-AlipayACodeRequest-v1';
import {WechatScene} from './externals/MaxCI-WechatScene-v1';
import {AlipayCodeRequest} from './externals/MaxCI-AlipayCodeRequest-v1';
import {AlipayAuthTokenRequest} from './externals/MaxCI-AlipayAuthTokenRequest-v1';
import {AlipayTemplate} from './externals/MaxCI-AlipayTemplate-v1';
import {LushpayMerchant} from './externals/MaxCI-LushpayMerchant-v1';
import {ShopSettlement} from './externals/MaxCI-ShopSettlement-v1';

declare const SERVICE_URL: string;

export const warehouse = createClient({url: SERVICE_URL, webSocketAdapter});

//刷新token
export const accessTokenRequests = warehouse.getResources(AccessTokenRequest);
//获取公共配置
export const platformConfigs = warehouse.getResources(PlatformConfig);
//微信登录
export const wechatLoginRequests = warehouse.getResources(WechatLoginRequest);
//手机号验证码
export const phoneCodeRequests = warehouse.getResources(PhoneCodeRequest);
//手机号登录
export const phoneLoginRequests = warehouse.getResources(PhoneLoginRequest);
//角色
export const roles = warehouse.getResources(Role);
//权限
export const permissions = warehouse.getResources(Permission);
//角色映射
export const roleMappings = warehouse.getResources(RoleMapping);
//应用
export const applications = warehouse.getResources(Application);
//创建应用
export const applicationCreateRequests = warehouse.getResources(
  ApplicationCreateRequest,
);
//更新应用
export const applicationRequests = warehouse.getResources(ApplicationRequest);
//渠道请求
export const channelRequests = warehouse.getResources(ChannelRequest);
//渠道
export const channels = warehouse.getResources(Channel);
//微信模板
export const wechatTemplates = warehouse.getResources(WechatTemplate);
//微信模板草稿
export const wechatTemplateDrafts = warehouse.getResources(WechatTemplateDraft);
//用户
export const users = warehouse.getResources(User);
//微信代码发布
export const wechatCodeRequests = warehouse.getResources(WechatCodeRequest);
//页面
export const pages = warehouse.getResources(Page);
//页面标签
export const pageLabels = warehouse.getResources(PageLabel);
//上传图片
export const uploadRequests = warehouse.getResources(UploadRequest);
//下载图片
export const downloadRequests = warehouse.getResources(DownloadRequest);
//店铺标签
export const shopLabels = warehouse.getResources(ShopLabel);
//店铺管理
export const shops = warehouse.getResources(Shop);
//商品标签
export const productLabels = warehouse.getResources(ProductLabel);
//商品管理
export const products = warehouse.getResources(Product);
//电商平台二级商户
export const payMerchants = warehouse.getResources(PayMerchant);
//电商平台二级商户预请求
export const wechatPayMerchantImports = warehouse.getResources(
  WechatPayMerchantImport,
);
//电商平台二级商户请求
export const wechatPayMerchantImportRequests = warehouse.getResources(
  WechatPayMerchantImportRequest,
);
//微信结算账户
export const wechatPayMerchatRequests = warehouse.getResources(
  WechatPayMerchatRequest,
);
//优惠券活动
export const couponActivitys = warehouse.getResources(CouponActivity);
//优惠券
export const coupons = warehouse.getResources(Coupon);
//配送商户
export const deliveryMerchants = warehouse.getResources(DeliveryMerchant);
//打印模板
export const printTemplates = warehouse.getResources(PrintTemplate);
//订单
export const orders = warehouse.getResources(Order);
//骑手审核
export const riderExamineRequests = warehouse.getResources(RiderExamineRequest);
//骑手报名
export const riderSignupRequests = warehouse.getResources(RiderSignupRequest);
//订单请求
export const applicationOrderRequests = warehouse.getResources(
  ApplicationOrderRequest,
);
//配送单分组标签
export const deliveryOrderGroupLabels = warehouse.getResources(
  DeliveryOrderGroupLabel,
);
//订单请求
export const deliveryOrderGroups = warehouse.getResources(DeliveryOrderGroup);
//配送门店
export const deliveryShops = warehouse.getResources(DeliveryShop);
//配送门店标签
export const deliveryShopLabels = warehouse.getResources(DeliveryShopLabel);
//骑手信息
export const riderInfos = warehouse.getResources(RiderInfo);
//骑手信息
export const deliveryDevelopers = warehouse.getResources(DeliveryDeveloper);
//支付交易
export const payTransactions = warehouse.getResources(PayTransaction);
//微信特约商户进件
export const wechatPaySpecialMerchantImports = warehouse.getResources(
  WechatPaySpecialMerchantImport,
);
//微信特约商户商户进件请求
export const wechatPaySpecialMerchantImportRequests = warehouse.getResources(
  WechatPaySpecialMerchantImportRequest,
);
//配送员订单
export const deliveryOrders = warehouse.getResources(DeliveryOrder);
//配置信息
export const configs = warehouse.getResources(Config);
//UI模板
export const uITemplates = warehouse.getResources(UITemplate);
//UI共享模板
export const sharedTemplates = warehouse.getResources(SharedTemplate);
//共享模板创建请求
export const sharedTemplateRequests = warehouse.getResources(
  SharedTemplateRequest,
);
//二手订单请求
export const usedOrderRequests = warehouse.getResources(UsedOrderRequest);
//二手商品
export const usedProducts = warehouse.getResources(UsedProduct);
//用户视图
export const userViews = warehouse.getResources(UserView);
//二手商品标签
export const usedProductLabels = warehouse.getResources(UsedProductLabel);
//兼职报名信息
export const signUps = warehouse.getResources(SignUp);
//售后订单
export const afterSaleOrders = warehouse.getResources(AfterSaleOrder);
//售后订单请求
export const afterSaleOrderRequests = warehouse.getResources(
  AfterSaleOrderRequest,
);
//售后订单请求
export const shopOrderRequests = warehouse.getResources(ShopOrderRequest);
//店铺请求
export const shopRequests = warehouse.getResources(ShopRequest);
//会员卡
export const memberCards = warehouse.getResources(MemberCard);
//话费充值
export const recharges = warehouse.getResources(Recharge);
//用户积分交易
export const userIntegrals = warehouse.getResources(UserIntegral);
//话费充值
export const userTransactions = warehouse.getResources(UserTransaction);
//用户积分调整请求
export const userIntegralRequests = warehouse.getResources(UserIntegralRequest);
//用户请求
export const userRequests = warehouse.getResources(UserRequest);
//优惠券派发请求
export const couponDistributeRequests = warehouse.getResources(
  CouponDistributeRequest,
);
//骑手审核请求
export const groupRiderExamineRequests = warehouse.getResources(
  GroupRiderExamineRequest,
);
//骑手报名请求
export const groupRiderSignupRequests = warehouse.getResources(
  GroupRiderSignupRequest,
);
//骑手信息
export const groupRiderInfos = warehouse.getResources(GroupRiderInfo);
//团购配送自提点
export const groupDeliveryPoints = warehouse.getResources(GroupDeliveryPoint);
//团购配送自提点标签
export const groupDeliveryPointLabels = warehouse.getResources(
  GroupDeliveryPointLabel,
);
//题目
export const questions = warehouse.getResources(Question);
//问题标签
export const questionLabels = warehouse.getResources(QuestionLabel);
//试卷
export const testPapes = warehouse.getResources(TestPaper);
//学生信息
export const students = warehouse.getResources(Student);
//答题记录
export const answerRecords = warehouse.getResources(AnswerRecord);
//配送门店请求
export const deliveryShopRequests = warehouse.getResources(DeliveryShopRequest);
//配送订单取消请求
export const deliveryOrderApplicationRequests = warehouse.getResources(
  DeliveryOrderApplicationRequest,
);
//微信用户
export const wechatUsers = warehouse.getResources(WechatUser);
//生成小程序码
export const wechatACodeRequests = warehouse.getResources(WechatACodeRequest);
//渠道移动应用
export const channelApps = warehouse.getResources(ChannelApp);
//渠道移动应用
export const clientVersions = warehouse.getResources(ClientVersion);
//打印机
export const printers = warehouse.getResources(Printer);
//打印机请求
export const printerRequests = warehouse.getResources(PrinterRequest);
//应用管理员删除角色请求
export const roleDeleteRequests = warehouse.getResources(RoleDeleteRequest);
//客服
export const mallCustomers = warehouse.getResources(MallCustomer);
//客服请求
export const mallCustomerRequests = warehouse.getResources(MallCustomerRequest);
//打印机请求
export const printerDistributeRequests = warehouse.getResources(
  PrinterDistributeRequest,
);
//支付宝小程序码请求
export const alipayACodeRequests = warehouse.getResources(AlipayACodeRequest);
//微信小程序码场景
export const wechatScenes = warehouse.getResources(WechatScene);
//支付宝代码请求
export const alipayCodeRequests = warehouse.getResources(AlipayCodeRequest);
//支付宝换取应用授权令牌请求
export const alipayAuthTokenRequests = warehouse.getResources(
  AlipayAuthTokenRequest,
);
//支付宝代码模板
export const alipayTemplates = warehouse.getResources(AlipayTemplate);
//青山支付商户
export const lushpayMerchants = warehouse.getResources(LushpayMerchant);
//应用结算
export const shopSettlements = warehouse.getResources(ShopSettlement);
