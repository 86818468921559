import Vue from 'vue';
import App from './App.vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Component from 'vue-class-component';
import router from '@/router';
import store from '@/store';

import 'normalize.css';
import ElementUI from 'element-ui';
import SvgIcon from 'vue-svgicon';
import '@/icons/components';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';

import * as filters from '@/filters';
import {ObjectId} from 'bson';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(ObjectId.prototype as any)[Symbol.toStringTag] = 'ObjectId';

Vue.use(ElementUI);
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
});
// Component.registerHooks([
//   'beforeRouteEnter',
//   'beforeRouteLeave',
//   'beforeRouteUpdate',
// ]);
// Register global filter functions
Object.entries(filters).forEach(([key, value]) => {
  Vue.filter(key, value);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
