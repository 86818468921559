/* eslint-disable */

import {ObjectId} from 'bson'

import {AnyObject} from '@warehouse/core'

export namespace WechatLoginRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'WechatLoginRequest';
  export const version: string = 'v1';
 export const type: WechatLoginRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 微信电话绑定请求
 */
export interface WechatLoginRequest {
  /**
   * 规格
   */
  spec: WechatCodeLoginRequestSpec | WechatPhoneLoginRequestSpec | WechatPhoneCodeLoginRequestSpec;
  status?: WechatLoginRequestStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 微信代码登录请求规格
 */
export interface WechatCodeLoginRequestSpec {
  /**
   * 类型
   */
  type: "微信代码";
  /**
   * 微信会话换取代码
   */
  code: string;
  /**
   * 设备
   */
  device: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 微信电话登录请求规格
 */
export interface WechatPhoneLoginRequestSpec {
  /**
   * 类型
   */
  type: "微信电话";
  /**
   * 微信Code登录请求ID
   */
  requestId: ObjectId;
  /**
   * 包括敏感数据在内的完整用户信息的加密数据
   */
  data: string;
  /**
   * 加密算法的初始向量
   */
  iv: string;
  /**
   * 设备
   */
  device: string;
  /**
   * 邀请人ID
   */
  inviterId?: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 微信电话验证码登录请求规格
 */
export interface WechatPhoneCodeLoginRequestSpec {
  /**
   * 类型
   */
  type: "电话验证码";
  /**
   * 微信Code登录请求ID
   */
  requestId: ObjectId;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 验证码
   */
  code: string;
  /**
   * 设备
   */
  device: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface WechatLoginRequestStatus {
  /**
   * 刷新令牌
   */
  refreshToken?: string;
  /**
   * 访问令牌
   */
  accessToken?: string;
  /**
   * 会话信息
   */
  session?: AnyObject;
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败" | "等待";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
