/* eslint-disable */

import {ObjectId} from 'bson'

export namespace ShopRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'ShopRequest';
  export const version: string = 'v1';
 export const type: ShopRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 地理位置范围
 */
export type GeometrySchema = Point | Polygon | MultiPolygon;
/**
 * 服务范围
 */
export type GeometrySchema1 = Point | Polygon | MultiPolygon;

/**
 * 店铺请求
 */
export interface ShopRequest {
  spec: ShopRequestSpec;
  status?: RequestResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface ShopRequestSpec {
  /**
   * 店铺LOGO
   */
  logo?: string;
  /**
   * 店铺地址
   */
  address?: string;
  geometry?: GeometrySchema;
  /**
   * 描述
   */
  description?: string;
  /**
   * 客服电话
   */
  phone?: string;
  serviceTime?: ServiceTime;
  /**
   * 备货时长（分钟）
   */
  stockUpTime?: number;
  auto?: ShopAuto;
  /**
   * 预定
   */
  reserve?: AcceptShopReserve | NotAcceptShopReserve;
  /**
   * 营业状态
   */
  service?: "开业" | "打烊";
  /**
   * 必点分类
   */
  requiredCategory?: string;
  /**
   * banner图片(最多3张)
   */
  banner?: [] | [string] | [string, string] | [string, string, string];
  applicationDelivery?: ApplicationDelivery;
  shopDelivery?: ShopDelivery;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 地理位置-点
 */
export interface Point {
  /**
   * 类型
   */
  type: "Point";
  /**
   * 坐标数据
   */
  coordinates: [number, number];
}
/**
 * 地理位置-多边形
 */
export interface Polygon {
  /**
   * 类型
   */
  type: "Polygon";
  /**
   * 坐标数据
   */
  coordinates: [number, number][][];
}
/**
 * 地理位置-多多边形
 */
export interface MultiPolygon {
  /**
   * 请求类型
   */
  type: "MultiPolygon";
  /**
   * 请求类型
   */
  coordinates: [number, number][][][];
}
/**
 * 营业时间
 */
export interface ServiceTime {
  /**
   * 星期列表
   */
  weeks?: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
  /**
   * 日期范围列表
   */
  dates?: DateRange[];
  /**
   * 时间范围列表
   */
  times?: TimeRange[];
}
/**
 * 日期范围
 */
export interface DateRange {
  /**
   * 开始日期
   */
  begin?: Date;
  /**
   * 结束日期
   */
  end?: Date;
}
/**
 * 时间范围
 */
export interface TimeRange {
  /**
   * 开始时间
   */
  begin?: string;
  /**
   * 结束时间
   */
  end?: string;
}
/**
 * 自动操作
 */
export interface ShopAuto {
  /**
   * 自动接单
   */
  autoAccept: boolean;
  /**
   * 自动配送
   */
  autoDeliver: boolean;
}
/**
 * 店铺接受预定
 */
export interface AcceptShopReserve {
  /**
   * 接受预定
   */
  acceptReserve: true;
  /**
   * 接受非营业时间预定
   */
  acceptCloseReserve: boolean;
  /**
   * 接受预定天数
   */
  acceptDays: ShopReserveDays[];
  /**
   * 间隔时间（分钟）
   */
  timeInterval: number;
}
/**
 * 店铺预定天
 */
export interface ShopReserveDays {
  days: NumberRange;
  times: TimeRange1;
}
/**
 * 天数范围列表
 */
export interface NumberRange {
  /**
   * 开始
   */
  begin?: number;
  /**
   * 结束
   */
  end?: number;
}
/**
 * 时间范围
 */
export interface TimeRange1 {
  /**
   * 开始时间
   */
  begin?: string;
  /**
   * 结束时间
   */
  end?: string;
}
/**
 * 店铺不接受预定
 */
export interface NotAcceptShopReserve {
  /**
   * 接受预定
   */
  acceptReserve: false;
}
/**
 * 应用配送
 */
export interface ApplicationDelivery {
  /**
   * 配送ID
   */
  deliveryId: ObjectId;
  /**
   * 门店ID
   */
  shopId: string;
  serviceRate: ServiceRate;
  /**
   * 启用
   */
  enable: true;
  /**
   * 配送范围
   */
  serviceScopes: DeliveryServiceScope[];
}
/**
 * 服务费
 */
export interface ServiceRate {
  /**
   * 比例(万分之)
   */
  rate: number;
  /**
   * 保底(分)
   */
  minimum: number;
}
/**
 * 应用配送
 */
export interface DeliveryServiceScope {
  scope: GeometrySchema1;
  /**
   * 最低消费
   */
  minimums: number;
  /**
   * 配送费
   */
  deliveryFee: number;
  /**
   * 配送时间
   */
  times: TimeRange[];
}
/**
 * 店铺配送
 */
export interface ShopDelivery {
  /**
   * 启用
   */
  enable: true;
  /**
   * 配送范围
   */
  serviceScopes: DeliveryServiceScope[];
}
/**
 * 状态
 */
export interface RequestResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
