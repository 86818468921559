/* eslint-disable */

import {ObjectId} from 'bson'

export namespace GroupRiderExamineRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'GroupRiderExamineRequest';
  export const version: string = 'v1';
 export const type: GroupRiderExamineRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 骑手审核请求
 */
export interface GroupRiderExamineRequest {
  spec: GroupRiderExamineRequestSpec;
  status?: RequestResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface GroupRiderExamineRequestSpec {
  /**
   * 注册请求ID
   */
  requestId: ObjectId;
  /**
   * 状态
   */
  status?: "通过" | "驳回";
  /**
   * 原因
   */
  reason?: string;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface RequestResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
