/* eslint-disable */

import {ObjectId} from 'bson'

import {AnyObject} from '@warehouse/core'

export namespace Page {
  export const group: string = 'MaxCI';
  export const kind: string = 'Page';
  export const version: string = 'v1';
 export const type: Page = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 页面
 */
export interface Page {
  spec: PageSpec;
  status?: ResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface PageSpec {
  /**
   * 名称
   */
  name: string;
  /**
   * 主图
   */
  image?: string;
  /**
   * 页面设置
   */
  setting: AnyObject;
  /**
   * 子页面列表
   */
  subPages: SubPage[];
  /**
   * 标签
   */
  labels: string[];
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 子页面规格
 */
export interface SubPage {
  /**
   * 名称
   */
  name: string;
  tabItem: TabItem;
  /**
   * 子页面设置
   */
  setting: AnyObject;
  /**
   * 组件列表
   */
  components: PageComponent[];
}
/**
 * 标签项
 */
export interface TabItem {
  /**
   * 文本
   */
  text: string;
  /**
   * 图标
   */
  images: [string, string];
  /**
   * 颜色
   */
  color: string;
  /**
   * 选择颜色
   */
  selectColor: string;
  /**
   * 字体大小
   */
  fontSize: number;
  /**
   * 选择字体大小
   */
  selectFontSize: number;
}
/**
 * 页面组件
 */
export interface PageComponent {
  /**
   * 类型
   */
  type: string;
  /**
   * 组件设置
   */
  setting: AnyObject;
}
/**
 * 状态
 */
export interface ResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: string;
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
