/* eslint-disable */

import {ObjectId} from 'bson'

export namespace Coupon {
  export const group: string = 'MaxCI';
  export const kind: string = 'Coupon';
  export const version: string = 'v1';
 export const type: Coupon = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 优惠券
 */
export interface Coupon {
  /**
   * 规格
   */
  spec: ShopCouponSpec | ApplicationCouponSpec;
  status?: CouponStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 店铺优惠券规格
 */
export interface ShopCouponSpec {
  /**
   * 包含产品列表
   */
  includProducts: ObjectId[];
  /**
   * 排除产品列表
   */
  excludProducts: ObjectId[];
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 名称
   */
  name: string;
  term: DateRange;
  /**
   * 订单收货类型
   */
  receiveTypes: ("应用" | "店铺" | "自取")[];
  /**
   * 订单金额
   */
  orderAmount: number;
  /**
   * 可用时段列表
   */
  times: TimeRange[];
  /**
   * 首单
   */
  firstOrder?: string;
  /**
   * 优惠
   */
  discount: CouponReduction | CouponRate | CouponGiving;
  /**
   * 简介
   */
  description?: string;
  /**
   * 优惠券活动ID
   */
  activityId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 期限
 */
export interface DateRange {
  /**
   * 开始日期
   */
  begin?: Date;
  /**
   * 结束日期
   */
  end?: Date;
}
/**
 * 时间范围
 */
export interface TimeRange {
  /**
   * 开始时间
   */
  begin?: string;
  /**
   * 结束时间
   */
  end?: string;
}
/**
 * 优惠券-减免
 */
export interface CouponReduction {
  /**
   * 类型
   */
  type: "减免";
  /**
   * 店铺优惠
   */
  shopDiscount: number;
  /**
   * 应用优惠
   */
  applicationDiscount: number;
}
/**
 * 优惠券-折扣
 */
export interface CouponRate {
  /**
   * 类型
   */
  type: "折扣";
  /**
   * 折扣率
   */
  rate: number;
}
/**
 * 优惠券-赠送
 */
export interface CouponGiving {
  /**
   * 类型
   */
  type: "赠送";
  /**
   * 数量
   */
  count: number;
  /**
   * 产品ID
   */
  productId: ObjectId;
  sku: ProductSKUSnapshot;
  /**
   * 属性
   */
  attributes: string[];
  snapshot?: ProductSnapshot;
}
/**
 * SKU
 */
export interface ProductSKUSnapshot {
  /**
   * 名称
   */
  name: string;
  /**
   * 主图
   */
  image?: string;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 成本价
   */
  costPrice?: number;
}
/**
 * 产品快照
 */
export interface ProductSnapshot {
  /**
   * 名称
   */
  name: string;
  /**
   * 商品图片(最多5张)
   */
  images:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string];
  /**
   * 商品规格
   */
  specs: ProductAttribute[];
  /**
   * 商品属性
   */
  attributes: ProductAttribute[];
  /**
   * 商品库存单位
   */
  skus: ProductSKUSnapshot1[];
  /**
   * 包装费
   */
  packingFee: number;
  purchaseQuantity?: PurchaseQuantity;
  /**
   * 标签
   */
  labels: string[];
  /**
   * 简介
   */
  description?: string;
  /**
   * 佣金比例
   */
  commissionRate?: number;
}
/**
 * 商品属性
 */
export interface ProductAttribute {
  /**
   * 名称
   */
  name: string;
  /**
   * 可选值列表
   */
  values: [string, ...string[]];
}
/**
 * 商品库存单位
 */
export interface ProductSKUSnapshot1 {
  /**
   * 名称
   */
  name: string;
  /**
   * 主图
   */
  image?: string;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 成本价
   */
  costPrice?: number;
}
/**
 * 购买数量
 */
export interface PurchaseQuantity {
  /**
   * 最小购买数量
   */
  min?: number;
  /**
   * 最大购买数量
   */
  max?: number;
}
/**
 * 应用优惠券规格
 */
export interface ApplicationCouponSpec {
  /**
   * 可用店铺列表
   */
  shopIds: ObjectId[];
  /**
   * 订单类型
   */
  orderTypes: ("商城" | "二手" | "代办")[];
  /**
   * 名称
   */
  name: string;
  term: DateRange1;
  /**
   * 订单收货类型
   */
  receiveTypes: ("应用" | "店铺" | "自取")[];
  /**
   * 订单金额
   */
  orderAmount: number;
  /**
   * 可用时段列表
   */
  times: TimeRange[];
  /**
   * 首单
   */
  firstOrder?: string;
  /**
   * 优惠
   */
  discount: CouponReduction | CouponRate | CouponGiving;
  /**
   * 简介
   */
  description?: string;
  /**
   * 优惠券活动ID
   */
  activityId: ObjectId;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 期限
 */
export interface DateRange1 {
  /**
   * 开始日期
   */
  begin?: Date;
  /**
   * 结束日期
   */
  end?: Date;
}
/**
 * 状态
 */
export interface CouponStatus {
  /**
   * 订单ID
   */
  orderId?: ObjectId;
  /**
   * 当前阶段
   */
  phase?: "未使用" | "已使用";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
