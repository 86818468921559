/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'goods': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M832 128H192c-35.2 0-64 28.8-64 64v640c0 35.2 28.8 64 64 64h640c35.2 0 64-28.8 64-64V192c0-35.2-28.8-64-64-64zM717.6 525.8C703.1 626.4 616.6 704 512 704c-104.5 0-191.1-77.6-205.6-178.2-19.8-5.9-34.4-24.1-34.4-45.8 0-26.5 21.5-48 48-48s48 21.5 48 48c0 20-12.2 37-29.5 44.3C352.1 607.9 424.6 672 512 672s159.9-64.1 173.5-147.7C668.2 517 656 500 656 480c0-26.5 21.5-48 48-48s48 21.5 48 48c0 21.7-14.6 39.9-34.4 45.8zM848 240c0 17.6-14.4 32-32 32H208c-17.6 0-32-14.4-32-32v-32c0-17.6 14.4-32 32-32h608c17.6 0 32 14.4 32 32v32z" p-id="8957"></path>'
  }
})
