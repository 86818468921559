/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'agent': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M512 64.2C264.7 64.2 64.3 264.7 64.3 512S264.8 959.7 512 959.7 959.8 759.3 959.8 512 759.3 64.2 512 64.2z m-15.9 103.6c1.8-0.6 4.1-0.5 5.1-2.6h20.3c8.2 3.8 17 6.1 24.3 12.2 16.2 13.7 23.4 35.6 17.5 55.4-6.3 21.4-23.2 35.9-44.9 38.4-28 3.2-53-14.8-58.7-42.4-5.4-26.1 10.5-52.7 36.4-61z m-222.8 95.5c52.9 35.8 103.2 75 153.6 114.2 39 30.3 77.9 60.7 116.7 91.3 17.2 13.6 24.9 31.7 20.6 53.2-4.4 22-18.3 36.6-40.4 41.6-22.1 5-40.8-2.2-54.6-19.9-60.7-77.9-122.2-155.2-180.1-235.2-10.8-15-21.6-30-30.9-46-1.4-2.3-2.8-4.6-3.4-8.8 7.5 2.1 13 5.9 18.5 9.6z m585.5 262.2c-1.1 1.1-0.6 2.5-0.7 3.8 0.1 1-0.2 2.1 0.7 3v8.1c-1.6 10.6-1.6 21.4-3.5 32.1-8.8 50.2-27.9 96.1-56.9 137.9-29.7 42.8-67.2 77.6-112.6 103.6-39 22.3-80.7 37.1-125.3 43.1-7.5 1-15.2 0.4-22.7 1.7h-5.4c-0.8-0.9-1.9-0.6-3-0.7-1.3 0.1-2.7-0.4-3.8 0.7h-27.1c-1.1-1.1-2.5-0.6-3.8-0.7-1 0.1-2.1-0.2-3 0.7h-8.1c-10.4-1.7-21-1.5-31.4-3.4-62.3-11.2-117.6-37.1-165.8-78.1-43.3-36.8-75.6-81.6-97.2-134-11.1-26.8-18.5-54.7-22.3-83.5-0.8-6.4-0.3-13-1.6-19.4v-8.1c0.9-0.8 0.6-1.9 0.7-3-0.1-1.3 0.4-2.7-0.7-3.8v-27.1c1.3-1.5 0.5-3.3 0.7-4.9-0.1-1.1 0.3-2.3-0.7-3.2v-5.4c3.1-7.1 2.5-14.8 3.6-22.2 4.4-30 12.5-59 24.4-86.9 6.1-14.3 22.7-21.9 37.6-17.2 21.1 6.7 31.1 26.6 23.3 47.2-6.1 16-11.6 32.2-15 49-10.1 49-7.9 97.2 8.1 144.7C261 640.4 282.9 676.2 313 707c26.5 27.1 57.3 47.9 92.3 62.5 37.6 15.7 76.8 22.9 117.5 21.2 55.3-2.2 105.5-19.6 150.6-51.5 42.6-30.2 74.3-69.4 95-117.3 19.9-45.9 27-93.8 20.6-143.4-10.1-78.7-47.6-141.6-110.3-189.9-12.2-9.4-25.7-16.6-38.6-24.9-6-3.9-11-8.7-14.6-14.8-8.1-13.6-5.6-30.3 6-42.2 8.9-9.2 24.2-10.6 37.6-3.6 71.1 36.9 123.9 91.5 158 164 16.9 36 26.7 74 30.4 113.6 0.1 1.4-0.7 3.3 1.4 4v5.4c-1.3 1.7-0.4 3.6-0.7 5.4v3.7c0.2 1.5-0.5 3.1 0.7 4.4-0.1 7.5-0.1 14.7-0.1 21.9z" p-id="2400"></path>'
  }
})
