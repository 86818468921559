/* eslint-disable */

import {ObjectId} from 'bson'

export namespace ChannelApp {
  export const group: string = 'MaxCI';
  export const kind: string = 'ChannelApp';
  export const version: string = 'v1';
 export const type: ChannelApp = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 渠道移动应用
 */
export interface ChannelApp {
  spec: ChannelAndroidAppSpec;
  status?: ChannelAppStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface ChannelAndroidAppSpec {
  /**
   * 应用平台
   */
  platform: "android";
  android: AppChannelAndroid;
  /**
   * 版本
   */
  version: string;
  /**
   * 流水线
   */
  pipeline?: number;
  /**
   * DCloud APPID
   */
  dcloudId?: string;
  /**
   * 高德应用API密钥
   */
  amapKey?: string;
  /**
   * 微信应用APPID
   */
  wechatId?: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * Android应用
 */
export interface AppChannelAndroid {
  /**
   * 包名
   */
  package: string;
  key: AppChannelAndroidKey;
  /**
   * DCloud APPKEY
   */
  dcloudKey: string;
  resource: AppChannelAndroidResource;
}
/**
 * 证书
 */
export interface AppChannelAndroidKey {
  /**
   * 内容
   */
  content: string;
  /**
   * 密码
   */
  password: string;
  /**
   * 别名
   */
  alias: string;
}
/**
 * 资源数据
 */
export interface AppChannelAndroidResource {
  /**
   * 应用图标
   */
  icon: string;
  /**
   * 应用通知图标
   */
  push?: string;
  /**
   * 应用开屏图片
   */
  splash?: string;
}
/**
 * 状态
 */
export interface ChannelAppStatus {
  /**
   * 发布文件
   */
  release?: string;
  /**
   * 当前阶段
   */
  phase?: "提交" | "生成" | "打包" | "发布" | "失败" | "取消";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
