/* eslint-disable */

import {ObjectId} from 'bson'

import {AnyObject} from '@warehouse/core'

export namespace UploadRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'UploadRequest';
  export const version: string = 'v1';
 export const type: UploadRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 上传请求
 */
export interface UploadRequest {
  /**
   * 规格
   */
  spec: UploadImageSpec | UploadDocumentSpec | UploadVideoSpec | UploadAudeoSpec | UploadFileSpec;
  status?: UploadRequestStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 上传图片规格
 */
export interface UploadImageSpec {
  /**
   * 类型
   */
  type: "图片";
  /**
   * 图片处理
   */
  process?: (
    | UploadImageAutoOrient
    | UploadImageBlur
    | UploadImageBright
    | UploadImageCircle
    | UploadImageContrast
    | UploadImageCrop
    | UploadImageFormat
    | UploadImageIndexCrop
    | UploadImageInterlace
    | UploadImageQuality
    | UploadImageResize
    | UploadImageRotate
    | UploadImageRoundedCorners
    | UploadImageSharpen
  )[];
  /**
   * 是否私有
   */
  private: boolean;
  /**
   * 用户ID
   */
  userId?: ObjectId;
  /**
   * 渠道ID
   */
  channelId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 图片自适应方向, https://help.aliyun.com/document_detail/44691.html
 */
export interface UploadImageAutoOrient {
  /**
   * 类型
   */
  type: "自适应方向";
  /**
   * 指定图片是否进行自适应旋转
   */
  value: boolean;
}
/**
 * 图片模糊, https://help.aliyun.com/document_detail/44701.html
 */
export interface UploadImageBlur {
  /**
   * 类型
   */
  type: "模糊";
  /**
   * 模糊半径
   */
  radius: number;
  /**
   * 正态分布的标准差
   */
  standardDeviation: number;
}
/**
 * 图片亮度, https://help.aliyun.com/document_detail/44698.html
 */
export interface UploadImageBright {
  /**
   * 类型
   */
  type: "亮度";
  /**
   * 指定图片的亮度
   */
  value: number;
}
/**
 * 图片内切圆, https://help.aliyun.com/document_detail/44695.html
 */
export interface UploadImageCircle {
  /**
   * 类型
   */
  type: "内切圆";
  /**
   * 内切圆的半径
   */
  radius: number;
}
/**
 * 图片对比度, https://help.aliyun.com/document_detail/44699.html
 */
export interface UploadImageContrast {
  /**
   * 类型
   */
  type: "对比度";
  /**
   * 指定图片的对比度
   */
  value: number;
}
/**
 * 图片裁剪, https://help.aliyun.com/document_detail/44693.html
 */
export interface UploadImageCrop {
  /**
   * 类型
   */
  type: "裁剪";
  /**
   * 裁剪起点横坐标
   */
  x?: number;
  /**
   * 裁剪起点纵坐标
   */
  y?: number;
  /**
   * 裁剪宽度
   */
  width?: number;
  /**
   * 裁剪高度
   */
  height?: number;
  /**
   * 设置裁剪的原点位置。原点按照九宫格的形式分布，一共有九个位置可以设置，为每个九宫格的左上角顶点。
   */
  grid?: "nw" | "north" | "ne" | "west" | "center" | "east" | "sw" | "south" | "se";
}
/**
 * 图片格式， https://help.aliyun.com/document_detail/44703.html
 */
export interface UploadImageFormat {
  /**
   * 类型
   */
  type: "格式";
  /**
   * 格式
   */
  value: "jpg" | "png" | "webp" | "bmp" | "gif" | "tiff";
}
/**
 * 图片索引裁剪, https://help.aliyun.com/document_detail/44696.html
 */
export interface UploadImageIndexCrop {
  /**
   * 类型
   */
  type: "索引裁剪";
  /**
   * 指定在x轴切割出的每块区域的长度。x参数与y参数只能任选其一。
   */
  x?: number;
  /**
   * 指定在y轴切割出的每块区域的长度。x参数与y参数只能任选其一。
   */
  y?: number;
  /**
   * 选择切割后返回的图片区域。
   */
  index: number;
}
/**
 * 图片渐进显示, https://help.aliyun.com/document_detail/44704.html
 */
export interface UploadImageInterlace {
  /**
   * 类型
   */
  type: "渐进显示";
  /**
   * 指定是否设置图片为渐进显示
   */
  value: boolean;
}
/**
 * 图片质量, https://help.aliyun.com/document_detail/44705.html
 */
export interface UploadImageQuality {
  /**
   * 类型
   */
  type: "质量";
  /**
   * 质量
   */
  value: number;
  /**
   * 相对质量
   */
  relative: number;
}
/**
 * 图片缩放， https://help.aliyun.com/document_detail/44688.html
 */
export interface UploadImageResize {
  /**
   * 类型
   */
  type: "缩放";
  /**
   * 缩放模式
   */
  mode: "lfit" | "mfit" | "fill" | "pad" | "fixed";
  /**
   * 目标缩放图宽度
   */
  width?: number;
  /**
   * 目标缩放图高度
   */
  height?: number;
  /**
   * 目标缩放图最长边
   */
  length?: number;
  /**
   * 目标缩放图最短边
   */
  short?: number;
  /**
   * 当目标缩放图大于原图时是否进行缩放
   */
  limit?: boolean;
  /**
   * 当缩放模式选择为pad（缩放填充）时，可以设置填充的颜色
   */
  color?: string;
  /**
   * 百分比缩放图片
   */
  percent?: number;
}
/**
 * 图片旋转, https://help.aliyun.com/document_detail/44690.html
 */
export interface UploadImageRotate {
  /**
   * 类型
   */
  type: "旋转";
  /**
   * 图片按顺时针旋转的角度
   */
  value: number;
}
/**
 * 图片圆角矩形, https://help.aliyun.com/document_detail/44694.html
 */
export interface UploadImageRoundedCorners {
  /**
   * 类型
   */
  type: "圆角矩形";
  /**
   * 将图片切出圆角，指定圆角的半径
   */
  radius: number;
}
/**
 * 图片锐化, https://help.aliyun.com/document_detail/44700.html
 */
export interface UploadImageSharpen {
  /**
   * 类型
   */
  type: "锐化";
  /**
   * 设置锐化效果的强度
   */
  value: number;
}
/**
 * 上传文档规格
 */
export interface UploadDocumentSpec {
  /**
   * 类型
   */
  type: "文档";
  /**
   * 是否私有
   */
  private: boolean;
  /**
   * 用户ID
   */
  userId?: ObjectId;
  /**
   * 渠道ID
   */
  channelId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 上传视频规格
 */
export interface UploadVideoSpec {
  /**
   * 类型
   */
  type: "视频";
  /**
   * 是否私有
   */
  private: boolean;
  /**
   * 用户ID
   */
  userId?: ObjectId;
  /**
   * 渠道ID
   */
  channelId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 上传音频规格
 */
export interface UploadAudeoSpec {
  /**
   * 类型
   */
  type: "音频";
  /**
   * 是否私有
   */
  private: boolean;
  /**
   * 用户ID
   */
  userId?: ObjectId;
  /**
   * 渠道ID
   */
  channelId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 上传文件规格
 */
export interface UploadFileSpec {
  /**
   * 类型
   */
  type: "文件";
  /**
   * 是否私有
   */
  private: boolean;
  /**
   * 用户ID
   */
  userId?: ObjectId;
  /**
   * 渠道ID
   */
  channelId?: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface UploadRequestStatus {
  upload?: UploadInfo;
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
/**
 * 上传信息
 */
export interface UploadInfo {
  /**
   * 上传地址
   */
  url?: string;
  /**
   * 上传数据
   */
  data?: AnyObject;
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
