
import {Component, Vue, Watch} from 'vue-property-decorator';
import {AppModule} from '@/store/modules/app';
import SidebarItem from './SidebarItem.vue';
import {SettingsModule} from '@/store/modules/settings';
import variables from '@/styles/_variables.scss';
import {applications} from '../../../resources';
import {ObjectId} from 'bson';

@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
  },
})
// interface A{aaa:string,bbb:number};
// interface B extends A {
//    CCC:number
//    };
// type C = Omit<B, 'aaa'>;
export default class extends Vue {
  private applicationName = ''; //应用名称
  private applicationLogo = ''; //应用logo
  private partTimeShow = false; //兼职是否开启
  private questionShow = false; //问卷是否开启
  private serviceShow = false; //会员功能是否开启
  private mallShow = false; //商城功能是否开启
  private agentShow = false; //跑腿功能是否开启
  private deliveryShow = false; //配送功能是否开启
  private usedShow = false; //二手功能是否开启
  private applicationId = '';
  private roleName = '';
  private routes: {
    path: string;
    redirect: string;
    name?: string;
    meta?: {
      hidden?: boolean;
      title?: string;
      icon?: string;
      roles?: Array<string>;
      alwaysShow?: boolean;
    };
    children?: {
      path: string;
      name: string;
      meta: {
        title: string;
        icon: string;
        affix?: boolean;
      };
    }[];
  }[] = [];
  async created() {
    if (
      this.$route.params.applicationId === localStorage.getItem('applicationId')
    ) {
      this.roleName = '平台管理员';
    } else {
      this.roleName = '应用管理员';
    }
    this.applicationId = this.$route.params.applicationId;
    await this.checkApplication();
  }
  private async updateRoutes() {
    const routes = [];
    //店铺管理员  应用管理员
    const role = localStorage.getItem('role');
    if (role === '店铺') {
      if (this.mallShow) {
        routes.push({
          path: '/application/' + this.applicationId + '/mall/',
          redirect: '/application/' + this.applicationId + '/mall/index',
          children: [
            {
              path: 'index',
              name: 'mall',
              meta: {title: '商城', icon: 'bill'},
            },
          ],
        });
      }
      routes.push(
        {
          path: '/application/' + this.applicationId + '/order/',
          redirect: '/application/' + this.applicationId + '/order/index',
          children: [
            {
              path: 'index',
              name: 'order',
              meta: {title: '订单', icon: 'form'},
            },
          ],
        },
        {
          path: '/application/' + this.applicationId + '/statistics/',
          redirect: '/application/' + this.applicationId + '/statistics/index',
          children: [
            {
              path: 'index',
              name: 'statistics',
              meta: {title: '财务', icon: 'setting'},
            },
          ],
        },
      );
    } else if (role === '财务') {
      // if (this.mallShow) {
      //   routes.push({
      //     path: '/application/' + this.applicationId + '/mall/',
      //     redirect: '/application/' + this.applicationId + '/mall/index',
      //     children: [
      //       {
      //         path: 'index',
      //         name: 'mall',
      //         meta: {title: '商城', icon: 'bill'},
      //       },
      //     ],
      //   });
      // }
      routes.push(
        {
          path: '/application/' + this.applicationId + '/order/',
          redirect: '/application/' + this.applicationId + '/order/index',
          children: [
            {
              path: 'index',
              name: 'order',
              meta: {title: '订单', icon: 'form'},
            },
          ],
        },
        {
          path: '/application/' + this.applicationId + '/statistics/',
          redirect: '/application/' + this.applicationId + '/statistics/index',
          children: [
            {
              path: 'index',
              name: 'statistics',
              meta: {title: '财务', icon: 'setting'},
            },
          ],
        },
      );
    } else {
      if (this.roleName === '平台管理员') {
        routes.push(
          {
            path: '/application/' + this.applicationId + '/user/',
            redirect: '/application/' + this.applicationId + '/user/index',
            children: [
              {
                path: 'index',
                name: 'user',
                meta: {title: '用户', icon: 'user'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/applications/',
            redirect:
              '/application/' + this.applicationId + '/applications/index',
            children: [
              {
                path: 'index',
                name: 'applications',
                meta: {title: '应用', icon: 'home'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/system/',
            redirect: '/application/' + this.applicationId + '/system/index',
            children: [
              {
                path: 'index',
                name: 'system',
                meta: {title: '系统', icon: 'system'},
              },
            ],
          },
        );
      } else {
        routes.push(
          {
            path: '/application/' + this.applicationId + '/overview/',
            redirect: '/application/' + this.applicationId + '/overview/index',
            children: [
              {
                path: 'index',
                name: 'overview',
                meta: {title: '概况', icon: 'agent'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/user/',
            redirect: '/application/' + this.applicationId + '/user/index',
            children: [
              {
                path: 'index',
                name: 'user',
                meta: {title: '用户', icon: 'user'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/template/',
            redirect: '/application/' + this.applicationId + '/template/index',
            children: [
              {
                path: 'index',
                name: 'template',
                meta: {title: '装修', icon: 'home'},
              },
            ],
          },
        );
        if (this.mallShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/mall/',
            redirect: '/application/' + this.applicationId + '/mall/index',
            children: [
              {
                path: 'index',
                name: 'mall',
                meta: {title: '商城', icon: 'bill'},
              },
            ],
          });
        }
        if (this.deliveryShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/delivery/',
            redirect: '/application/' + this.applicationId + '/delivery/index',
            children: [
              {
                path: 'index',
                name: 'delivery',
                meta: {title: '配送', icon: 'rider'},
              },
            ],
          });
        }
        if (this.agentShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/agent/',
            redirect: '/application/' + this.applicationId + '/agent/index',
            children: [
              {
                path: 'index',
                name: 'agent',
                meta: {title: '跑腿', icon: 'agent'},
              },
            ],
          });
        }
        if (this.usedShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/used/',
            redirect: '/application/' + this.applicationId + '/used/index',
            children: [
              {
                path: 'index',
                name: 'used',
                meta: {title: '二手', icon: 'agent'},
              },
            ],
          });
        }
        if (this.partTimeShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/partTime/',
            redirect: '/application/' + this.applicationId + '/partTime/index',
            children: [
              {
                path: 'index',
                name: 'partTime',
                meta: {title: '兼职', icon: 'bill'},
              },
            ],
          });
        }
        routes.push(
          {
            path: '/application/' + this.applicationId + '/order/',
            redirect: '/application/' + this.applicationId + '/order/index',
            children: [
              {
                path: 'index',
                name: 'order',
                meta: {title: '订单', icon: 'form'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/statistics/',
            redirect:
              '/application/' + this.applicationId + '/statistics/index',
            children: [
              {
                path: 'index',
                name: 'statistics',
                meta: {title: '财务', icon: 'setting'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/channel/',
            redirect: '/application/' + this.applicationId + '/channel/index',
            children: [
              {
                path: 'index',
                name: 'channel',
                meta: {title: '渠道', icon: 'home'},
              },
            ],
          },
        );
        if (this.questionShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/questionnaire/',
            redirect:
              '/application/' + this.applicationId + '/questionnaire/index',
            children: [
              {
                path: 'index',
                name: 'questionnaire',
                meta: {title: '问卷', icon: 'marketing'},
              },
            ],
          });
        }
        if (this.serviceShow) {
          routes.push({
            path: '/application/' + this.applicationId + '/service/',
            redirect: '/application/' + this.applicationId + '/service/index',
            children: [
              {
                path: 'index',
                name: 'service',
                meta: {title: '服务', icon: 'agent'},
              },
            ],
          });
        }
        routes.push(
          {
            path: '/application/' + this.applicationId + '/activity/',
            redirect: '/application/' + this.applicationId + '/activity/index',
            children: [
              {
                path: 'index',
                name: 'activity',
                meta: {title: '营销', icon: 'marketing'},
              },
            ],
          },
          {
            path: '/application/' + this.applicationId + '/setting/',
            redirect: '/application/' + this.applicationId + '/setting/index',
            children: [
              {
                path: 'index',
                name: 'setting',
                meta: {title: '设置', icon: 'setting'},
              },
            ],
          },
        );
      }
    }
    routes.push({path: '*', redirect: '/404', meta: {hidden: true}});
    this.routes = routes;
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  @Watch('$route.path')
  changeparams(to: string, from: string) {
    //清除列表记录分页搜索条件的缓存
    if (
      !(to.indexOf('mall/index') > 0 && from.indexOf('goods/index') > 0) &&
      !(from.indexOf('mall/index') > 0 && to.indexOf('goods/index') > 0) &&
      !(to.indexOf('order/index') > 0 && from.indexOf('user/detail') > 0) &&
      !(from.indexOf('order/index') > 0 && to.indexOf('user/detail') > 0)
    ) {
      localStorage.setItem('searchData', '');
      localStorage.setItem('listParams', '');
    }
  }
  @Watch('$route')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routechange(to: any, from: any) {
    this.$store.state.lastRoute = from;
    if (to.params.applicationId === localStorage.getItem('applicationId')) {
      this.roleName = '平台管理员';
    } else {
      this.roleName = '应用管理员';
    }
    this.updateRoutes();
  }
  @Watch('$store.state.applicationId')
  async changeAppId(val: string) {
    if (val === localStorage.getItem('applicationId')) {
      this.roleName = '平台管理员';
    } else {
      this.roleName = '应用管理员';
    }
    this.applicationId = val;
    await this.checkApplication();
  }
  private async checkApplication() {
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
          );
          return match;
        }),
      )
    ).find(() => true);
    this.applicationName = application?.spec.name ?? '';
    this.applicationLogo = application?.spec.logo ?? '';
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '商城') {
          this.mallShow = true;
        }
        if (item === '跑腿') {
          this.agentShow = true;
        }
        if (item === '配送') {
          this.deliveryShow = true;
        }
        if (item === '二手') {
          this.usedShow = true;
        }
        if (item === '会员卡') {
          this.serviceShow = true;
        }
      });
    }
    await this.updateRoutes();
  }
  get sidebar() {
    return AppModule.sidebar;
  }
  get variables() {
    return variables;
  }
  get isCollapse() {
    return !this.sidebar.opened;
  }
  get menuActiveTextColor() {
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme;
    } else {
      return variables.menuActiveText;
    }
  }

  get activeMenu() {
    const route = this.$route;
    const {meta, path} = route;
    // if set path, the sidebar will highlight the path you set
    if (meta?.activeMenu) {
      return meta?.activeMenu;
    }
    return path;
  }
}
