
import * as pathToRegexp from 'path-to-regexp';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {RouteRecord, Route} from 'vue-router';
@Component({
  name: 'Breadcrumb',
})
export default class extends Vue {
  private role = localStorage.getItem('role');
  private breadcrumbs: RouteRecord[] = [];
  @Watch('$route')
  private onRouteChange(route: Route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return;
    }
    this.getBreadcrumb();
  }
  created() {
    this.getBreadcrumb();
  }
  private getBreadcrumb() {
    let matched = this.$route.matched.filter(
      item => item.meta && item.meta.title,
    );
    const first = matched[0];
    if (!this.isDashboard(first)) {
      if (this.role !== '店铺' && this.role !== '财务') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        matched = [{path: '/', meta: {title: '小微极创'}} as any].concat(
          matched,
        );
      }
    }
    this.breadcrumbs = matched.filter(item => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false;
    });
    // console.log('breadcrumbs', this.breadcrumbs);
  }
  private isDashboard(route: RouteRecord) {
    const name = route && route.name;
    if (!name) {
      return false;
    }
    return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
  }
  private pathCompile(path: string) {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const {params} = this.$route;
    const toPath = pathToRegexp.compile(path);
    return toPath(params);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleLink(item: any) {
    const {redirect, path} = item;
    if (redirect) {
      this.$router.push(redirect);
      return;
    }
    this.$router.push(this.pathCompile(path));
  }
}
