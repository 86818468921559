
import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import {DeviceType, AppModule} from '@/store/modules/app';
import {SettingsModule} from '@/store/modules/settings';
import RightPanel from '@/components/RightPanel/index.vue';
import {AppMain, Navbar, Settings, Sidebar} from './components';
import ResizeMixin from './mixin/resize';

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
    RightPanel,
    Settings,
  },
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile,
    };
  }

  get showSettings() {
    return SettingsModule.showSettings;
  }
  private handleClickOutside() {
    AppModule.CloseSideBar(false);
  }
}
