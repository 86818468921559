/* eslint-disable */

import {ObjectId} from 'bson'

export namespace ChannelRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'ChannelRequest';
  export const version: string = 'v1';
 export const type: ChannelRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 渠道请求
 */
export interface ChannelRequest {
  /**
   * 规格
   */
  spec:
    | ChannelDeleteRequestSpec
    | ChannelUpdateAuthorizationRequestSpec
    | ChannelBindTemplateRequestSpec
    | ChannelUpdateAppRequestSpec
    | ChannelMakeAppRequestSpec
    | ChannelUnMakeAppRequestSpec
    | ChannelUpdatePrivacyRequestSpec
    | ChannelUpdateWechatMiniAppRequestSpec
    | RegisterWechatMiniAppRequestSpec
    | RegisterAlipayMiniAppRequestSpec;
  status?: ChannelRequestStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 渠道删除请求
 */
export interface ChannelDeleteRequestSpec {
  /**
   * 类型
   */
  type: "删除渠道";
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 渠道更新授权请求
 */
export interface ChannelUpdateAuthorizationRequestSpec {
  /**
   * 类型
   */
  type: "更新授权";
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 渠道绑定模板请求
 */
export interface ChannelBindTemplateRequestSpec {
  /**
   * 类型
   */
  type: "绑定模板";
  /**
   * 模板ID
   */
  templateId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 渠道更新移动应用请求
 */
export interface ChannelUpdateAppRequestSpec {
  /**
   * 类型
   */
  type: "更新移动应用";
  /**
   * DCloud APPID
   */
  dcloudId?: string;
  android?: AppChannelAndroid;
  /**
   * 高德应用API密钥
   */
  amapKey?: string;
  wechat?: WechatAppData;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * Android应用
 */
export interface AppChannelAndroid {
  /**
   * 包名
   */
  package: string;
  key: AppChannelAndroidKey;
  /**
   * DCloud APPKEY
   */
  dcloudKey: string;
  resource: AppChannelAndroidResource;
}
/**
 * 证书
 */
export interface AppChannelAndroidKey {
  /**
   * 内容
   */
  content: string;
  /**
   * 密码
   */
  password: string;
  /**
   * 别名
   */
  alias: string;
}
/**
 * 资源数据
 */
export interface AppChannelAndroidResource {
  /**
   * 应用图标
   */
  icon: string;
  /**
   * 应用通知图标
   */
  push?: string;
  /**
   * 应用开屏图片
   */
  splash?: string;
}
/**
 * 微信应用数据
 */
export interface WechatAppData {
  /**
   * 应用ID
   */
  appId: string;
  /**
   * 应用秘钥
   */
  appSecret: string;
}
/**
 * 渠道生成移动应用请求
 */
export interface ChannelMakeAppRequestSpec {
  /**
   * 类型
   */
  type: "生成移动应用";
  /**
   * 应用平台
   */
  platform: "android";
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 渠道取消生成移动应用请求
 */
export interface ChannelUnMakeAppRequestSpec {
  /**
   * 类型
   */
  type: "取消生成移动应用";
  /**
   * 渠道移动应用ID
   */
  channelAppId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 渠道更新用户隐私保护指引
 */
export interface ChannelUpdatePrivacyRequestSpec {
  /**
   * 类型
   */
  type: "更新隐私指引";
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 渠道更新微信小程序请求
 */
export interface ChannelUpdateWechatMiniAppRequestSpec {
  /**
   * 类型
   */
  type: "更新微信小程序";
  /**
   * 订阅消息模板ID
   */
  subscribeMessageTemplateId: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 注册微信小程序请求
 */
export interface RegisterWechatMiniAppRequestSpec {
  /**
   * 类型
   */
  type: "注册微信小程序";
  /**
   * 企业名称
   */
  companyName: string;
  /**
   * 企业代码类型
   */
  codeType: "统一社会信用代码" | "组织机构代码" | "营业执照注册号";
  /**
   * 企业代码
   */
  enterpriseCode: string;
  /**
   * 法人微信号
   */
  legalPersonaWechat: string;
  /**
   * 法人姓名
   */
  legalPersonaName: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 注册支付宝小程序请求
 */
export interface RegisterAlipayMiniAppRequestSpec {
  /**
   * 类型
   */
  type: "注册支付宝小程序";
  /**
   * 商家登录支付宝的邮箱帐号或手机号
   */
  alipayAccount: string;
  /**
   * 商家法人名称
   */
  legalPersonalName: string;
  /**
   * 营业执照企业名称
   */
  certName: string;
  /**
   * 营业执照编码
   */
  certNo: string;
  /**
   * 小程序名称
   */
  appName: string;
  /**
   * 商家联系人手机电话
   */
  contactPhone: string;
  /**
   * 商家联系人名称
   */
  contactName: string;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface ChannelRequestStatus {
  /**
   * 微信预授权码
   */
  preAuthCode?: string;
  /**
   * 支付宝三方应用APPID
   */
  appId?: string;
  /**
   * 企业代码
   */
  enterpriseCode?: string;
  /**
   * 当前阶段
   */
  phase?: "成功" | "进行中" | "失败";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
