import {PlatformConfigSpec} from '@/externals/MaxCI-PlatformConfig-v1';
import Vue from 'vue';
import Vuex from 'vuex';
// import {AppState} from './modules/app';
// import {SettingsState} from './modules/settings';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    applicationId: '',
    pConfig: undefined as PlatformConfigSpec | undefined,
    mallServices: '',
    usedServices: '',
    agentServices: '',
    questionServices: '',
    shopAuthority: [] as Array<string>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastRoute: null as any,
  },
  mutations: {},
});

export default store;

// export interface RootState {
//   app: AppState;
//   settings: SettingsState;
//   test: string,
//   num: number,
// }

// // Declare empty store first, dynamically register all modules later.
// export default new Vuex.Store<RootState>({});
