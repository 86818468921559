/* eslint-disable */

import {ObjectId} from 'bson'

import {AnyObject} from '@warehouse/core'

export namespace PrinterRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'PrinterRequest';
  export const version: string = 'v1';
 export const type: PrinterRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 打印机请求
 */
export interface PrinterRequest {
  /**
   * 规格
   */
  spec: PrinterBindSpec | PrinterUnbindSpec | PrinterUpdateSpec | PrinterSetLogoSpec | PrinterUpdateTemplateSpec;
  status?: RequestResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 打印机请求绑定规格
 */
export interface PrinterBindSpec {
  /**
   * 类型
   */
  type: "绑定";
  /**
   * 提供商
   */
  provider: "易联云" | "飞鹅云";
  /**
   * 绑定数据
   */
  data: AnyObject;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
}
/**
 * 打印机请求解绑规格
 */
export interface PrinterUnbindSpec {
  /**
   * 类型
   */
  type: "解绑";
  /**
   * 打印机ID
   */
  printerId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
}
/**
 * 修改备注规格
 */
export interface PrinterUpdateSpec {
  /**
   * 类型
   */
  type: "修改";
  /**
   * 打印机ID
   */
  printerId: ObjectId;
  /**
   * 备注
   */
  remark: string;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
}
/**
 * 设置logo规格
 */
export interface PrinterSetLogoSpec {
  /**
   * 类型
   */
  type: "设置logo";
  /**
   * 提供商
   */
  provider: "易联云";
  /**
   * 打印logo
   */
  logo: string;
  /**
   * 打印机ID
   */
  printerId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 设置打印模板规格
 */
export interface PrinterUpdateTemplateSpec {
  /**
   * 类型
   */
  type: "设置模板";
  /**
   * 模板ID
   */
  templateId: ObjectId;
  /**
   * 打印机ID
   */
  printerId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface RequestResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
