/* eslint-disable */

import {ObjectId} from 'bson'

export namespace DeliveryMerchant {
  export const group: string = 'MaxCI';
  export const kind: string = 'DeliveryMerchant';
  export const version: string = 'v1';
 export const type: DeliveryMerchant = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 服务范围
 */
export type GeometrySchema = Point | Polygon | MultiPolygon;

/**
 * 配送提供商
 */
export interface DeliveryMerchant {
  spec: DeliveryMerchantSpec;
  status?: DeliveryMerchantStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface DeliveryMerchantSpec {
  /**
   * 类型
   */
  type: "极创" | "达达" | "顺丰同城" | "蜂鸟";
  /**
   * 名称
   */
  name: string;
  /**
   * 开发者ID
   */
  appId: string;
  /**
   * 开发者秘钥
   */
  appSecret: string;
  /**
   * 配送范围
   */
  serviceScopes: DeliveryServiceScope[];
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 应用配送
 */
export interface DeliveryServiceScope {
  scope: GeometrySchema;
  /**
   * 最低消费
   */
  minimums: number;
  /**
   * 配送费
   */
  deliveryFee: number;
  /**
   * 配送时间
   */
  times: TimeRange[];
}
/**
 * 地理位置-点
 */
export interface Point {
  /**
   * 类型
   */
  type: "Point";
  /**
   * 坐标数据
   */
  coordinates: [number, number];
}
/**
 * 地理位置-多边形
 */
export interface Polygon {
  /**
   * 类型
   */
  type: "Polygon";
  /**
   * 坐标数据
   */
  coordinates: [number, number][][];
}
/**
 * 地理位置-多多边形
 */
export interface MultiPolygon {
  /**
   * 请求类型
   */
  type: "MultiPolygon";
  /**
   * 请求类型
   */
  coordinates: [number, number][][][];
}
/**
 * 时间范围
 */
export interface TimeRange {
  /**
   * 开始时间
   */
  begin?: string;
  /**
   * 结束时间
   */
  end?: string;
}
/**
 * 状态
 */
export interface DeliveryMerchantStatus {
  /**
   * 当前阶段
   */
  phase?: "就绪" | "禁用" | "待授权";
  /**
   * 访问令牌
   */
  accessToken: string;
  /**
   * 刷新令牌
   */
  refreshToken?: string;
  /**
   * 访问令牌有效期
   */
  expireIn?: string;
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
