/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setting': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M506.368 675.328A163.328 163.328 0 10343.04 512a163.84 163.84 0 00163.328 163.328zM124.928 512a417.792 417.792 0 017.68-80.384A142.848 142.848 0 00242.176 358.4a153.6 153.6 0 005.632-138.752 377.344 377.344 0 01142.848-87.04 139.776 139.776 0 00235.008 0A377.856 377.856 0 01768 220.672a153.6 153.6 0 006.656 137.728 142.848 142.848 0 00104.96 71.68 418.816 418.816 0 01-3.072 175.104 143.36 143.36 0 00-102.4 73.216 153.6 153.6 0 00-10.752 125.952 377.856 377.856 0 01-146.432 88.064 139.264 139.264 0 00-219.648 0A376.832 376.832 0 01250.88 806.4a153.6 153.6 0 00-9.728-129.024 142.848 142.848 0 00-106.496-73.728A416.768 416.768 0 01124.928 512z"/>'
  }
})
