import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

export enum DeviceType { // 定义设备枚举类型
  Mobile,
  Desktop,
}

export interface AppState {
  // 定义state接口类型
  device: DeviceType;
  sidebar: {
    opened: boolean;
    withoutAnimation: boolean;
  };
}

@Module({dynamic: true, store, name: 'app'})
class App extends VuexModule implements AppState {
  public sidebar = {
    opened: true,
    withoutAnimation: false,
  };
  public device = DeviceType.Desktop;

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    // 切换siderBar状态
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
  }

  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = false;
    this.sidebar.withoutAnimation = withoutAnimation;
  }

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device;
  }

  @Action
  public ToggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation);
  }

  @Action
  public CloseSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation);
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device);
  }
}

export const AppModule = getModule(App);
