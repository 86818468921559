/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shop': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M1018.88 369.664q5.12 35.84-11.264 68.608t-53.248 53.248l0 340.992q0 29.696-10.24 53.76t-28.16 41.472-40.96 27.136-48.64 9.728l-575.488 0q-27.648 0-51.2-9.728t-41.472-27.136-27.648-41.472-9.728-53.76l0-340.992q-35.84-21.504-53.248-53.76t-11.264-68.096l51.2-246.784q5.12-24.576 24.064-41.472t44.544-16.896l204.8 0 317.44 0 197.632 0q30.72 0 50.688 16.896t25.088 41.472zM888.832 521.216q-14.336 3.072-29.696 3.072-32.768 0-61.44-11.776t-50.688-32.256-34.816-48.128-13.824-59.392q-1.024 31.744-13.312 59.392t-33.792 48.128-50.176 32.256-61.44 11.776-61.952-12.288-50.688-33.28-33.792-49.152-12.288-59.904q0 31.744-13.312 59.904t-35.84 49.152-52.736 33.28-62.976 12.288q-15.36 0-30.72-3.072l0 118.784q0 12.288 5.632 24.576t14.848 21.504 20.48 14.848 22.528 5.632l577.536 0q10.24 0 21.504-5.12t20.48-13.312 14.848-19.968 5.632-24.064l0-122.88z" p-id="31481"></path>'
  }
})
