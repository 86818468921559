/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M861.090909 786.385455a158.487273 158.487273 0 0 1-33.978182 101.934545A102.4 102.4 0 0 1 744.727273 930.909091H279.272727a102.167273 102.167273 0 0 1-82.152727-42.589091A158.952727 158.952727 0 0 1 162.909091 786.385455 744.727273 744.727273 0 0 1 167.563636 698.181818a467.083636 467.083636 0 0 1 17.221819-83.083636 279.272727 279.272727 0 0 1 31.883636-70.516364 150.807273 150.807273 0 0 1 51.2-48.64 144.523636 144.523636 0 0 1 73.541818-18.850909 235.752727 235.752727 0 0 0 170.589091 69.818182 235.752727 235.752727 0 0 0 170.821818-69.818182 142.429091 142.429091 0 0 1 73.541818 18.850909 153.134545 153.134545 0 0 1 51.2 48.64 279.272727 279.272727 0 0 1 31.883637 71.214546 462.894545 462.894545 0 0 1 17.221818 82.385454 800.116364 800.116364 0 0 1 4.421818 88.203637zM660.014545 154.530909A201.774545 201.774545 0 0 1 721.454545 302.545455a201.774545 201.774545 0 0 1-61.44 148.014545A201.774545 201.774545 0 0 1 512 512a201.774545 201.774545 0 0 1-148.014545-61.44A201.774545 201.774545 0 0 1 302.545455 302.545455a201.774545 201.774545 0 0 1 61.44-148.014546A201.774545 201.774545 0 0 1 512 93.090909a201.774545 201.774545 0 0 1 148.014545 61.44z" p-id="22538"></path>'
  }
})
