/* eslint-disable */

import {ObjectId} from 'bson'

export namespace TestPaper {
  export const group: string = 'MaxCI';
  export const kind: string = 'TestPaper';
  export const version: string = 'v1';
 export const type: TestPaper = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 试卷
 */
export interface TestPaper {
  spec: TestPaperSpec;
  status?: TestPaperStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface TestPaperSpec {
  /**
   * 试卷名称
   */
  name: string;
  testTime: DateRange;
  /**
   * 总时长（秒）
   */
  totalTime?: number;
  /**
   * 及格分
   */
  qualifyScore: number;
  /**
   * 总分
   */
  totalScore: number;
  /**
   * 取题数量
   */
  questionNumber: number | TakeTopic[];
  /**
   * 题目
   */
  questions: ExamQuestion[];
  /**
   * 背景图
   */
  background: string;
  /**
   * 打乱题目
   */
  upset: boolean;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 考试时间
 */
export interface DateRange {
  /**
   * 开始日期
   */
  begin?: Date;
  /**
   * 结束日期
   */
  end?: Date;
}
/**
 * 标签取提
 */
export interface TakeTopic {
  /**
   * 标签
   */
  label: string;
  /**
   * 数量
   */
  number: number;
}
/**
 * 考试题目
 */
export interface ExamQuestion {
  /**
   * 标签
   */
  label: string;
  /**
   * 分数
   */
  score: number;
  /**
   * 时长（秒）
   */
  totalTime: number;
  /**
   * 题目ID
   */
  questionId: ObjectId;
}
/**
 * 状态
 */
export interface TestPaperStatus {
  /**
   * 当前阶段
   */
  phase?: "就绪" | "禁用";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
