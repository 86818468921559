/* eslint-disable */

import {ObjectId} from 'bson'

export namespace PayTransaction {
  export const group: string = 'MaxCI';
  export const kind: string = 'PayTransaction';
  export const version: string = 'v1';
 export const type: PayTransaction = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 支付交易
 */
export interface PayTransaction {
  /**
   * 规格
   */
  spec:
    | WechatMallPayTransactionSpec
    | WechatMallRefundTransactionSpec
    | WechatAgentPayTransactionSpec
    | WechatAgentRefundTransactionSpec
    | WechatUsedPayTransactionSpec
    | WechatUsedRefundTransactionSpec
    | WechatMemberCardPayTransactionSpec
    | WechatMemberCardRefundTransactionSpec
    | WechatRechargePayTransactionSpec
    | WechatRechargeRefundTransactionSpec;
  status?: RequestResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 微信商城支付交易规格
 */
export interface WechatMallPayTransactionSpec {
  /**
   * 方向
   */
  direction: "收入";
  /**
   * 交易类型
   */
  orderType: "商城";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: MallOrderAmount;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface MallOrderAmount {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  integral: MallDiscount2;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 满减金额
 */
export interface MallDiscount {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 优惠券金额
 */
export interface MallDiscount1 {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 积分抵扣金额
 */
export interface MallDiscount2 {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信商城退款交易规格
 */
export interface WechatMallRefundTransactionSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 订单类型
   */
  orderType: "商城";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: MallOrderAmount1;
  /**
   * 退款原因
   */
  reason: string;
  /**
   * 第三方退款ID
   */
  payRefundId?: string;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface MallOrderAmount1 {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  integral: MallDiscount2;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信跑腿支付交易规格
 */
export interface WechatAgentPayTransactionSpec {
  /**
   * 方向
   */
  direction: "收入";
  /**
   * 交易类型
   */
  orderType: "跑腿";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: AgentOrderAmount;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface AgentOrderAmount {
  /**
   * 跑腿费
   */
  agent: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信跑腿退款交易规格
 */
export interface WechatAgentRefundTransactionSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 订单类型
   */
  orderType: "跑腿";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: AgentOrderAmount1;
  /**
   * 退款原因
   */
  reason: string;
  /**
   * 第三方退款ID
   */
  payRefundId?: string;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface AgentOrderAmount1 {
  /**
   * 跑腿费
   */
  agent: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信二手支付交易规格
 */
export interface WechatUsedPayTransactionSpec {
  /**
   * 方向
   */
  direction: "收入";
  /**
   * 交易类型
   */
  orderType: "二手";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: UsedOrderAmount;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface UsedOrderAmount {
  /**
   * 邮费
   */
  postage: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信二手退款交易规格
 */
export interface WechatUsedRefundTransactionSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 订单类型
   */
  orderType: "二手";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: UsedOrderAmount1;
  /**
   * 退款原因
   */
  reason: string;
  /**
   * 第三方退款ID
   */
  payRefundId?: string;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface UsedOrderAmount1 {
  /**
   * 邮费
   */
  postage: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信会员卡支付交易规格
 */
export interface WechatMemberCardPayTransactionSpec {
  /**
   * 方向
   */
  direction: "收入";
  /**
   * 交易类型
   */
  orderType: "会员卡";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: MemberCardOrderAmount;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface MemberCardOrderAmount {
  /**
   * 会员卡金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信会员卡退款交易规格
 */
export interface WechatMemberCardRefundTransactionSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 订单类型
   */
  orderType: "会员卡";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: UsedOrderAmount2;
  /**
   * 退款原因
   */
  reason: string;
  /**
   * 第三方退款ID
   */
  payRefundId?: string;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface UsedOrderAmount2 {
  /**
   * 邮费
   */
  postage: number;
  /**
   * 服务费
   */
  service: number;
  /**
   * 商品金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信会充值支付交易规格
 */
export interface WechatRechargePayTransactionSpec {
  /**
   * 方向
   */
  direction: "收入";
  /**
   * 交易类型
   */
  orderType: "充值";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: RechargeOrderAmount;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface RechargeOrderAmount {
  /**
   * 话费充值金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 微信充值退款交易规格
 */
export interface WechatRechargeRefundTransactionSpec {
  /**
   * 收支类型
   */
  direction: "支出";
  /**
   * 订单类型
   */
  orderType: "充值";
  /**
   * 支付商户类型
   */
  payMerchantType: "微信" | "支付宝";
  /**
   * 第三方交易ID
   */
  payTransactionId: string;
  amount: RechargeOrderAmount1;
  /**
   * 退款原因
   */
  reason: string;
  /**
   * 第三方退款ID
   */
  payRefundId?: string;
  /**
   * 支付商户ID
   */
  payMerchantId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 交易金额
 */
export interface RechargeOrderAmount1 {
  /**
   * 话费充值金额
   */
  product: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 状态
 */
export interface RequestResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
