
import {
  warehouse,
  accessTokenRequests,
  platformConfigs,
  roles,
  roleMappings,
} from './resources';
import {Component, Vue} from 'vue-property-decorator';
import {getUserId} from './api/publicMethod';
import {EJSON} from 'bson';
import {Role} from '@/externals/Core-Role-v1';
import {ObjectId} from 'bson';

@Component({
  name: 'App',
})
export default class extends Vue {
  async created() {
    const identity = localStorage.getItem('role');
    //处理登陆失效
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      warehouse.token = accessToken;
    }
    warehouse.setErrorHandler(401, async err => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          let applicationId = null as null | ObjectId;
          if (identity === '店铺' || identity === '财务') {
            applicationId = ObjectId.createFromHexString(
              localStorage.getItem('financeApplicationId') ?? '',
            );
          } else {
            const platformConfig = (
              await platformConfigs.find(stage => stage)
            ).find(() => true);
            applicationId = platformConfig?.spec.applicationId ?? null;
          }
          if (applicationId) {
            warehouse.token = undefined;
            const accessTokenRequest = await accessTokenRequests.create(
              [
                {
                  spec: {
                    refreshToken: refreshToken,
                    device: '浏览器',
                    applicationId: applicationId,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            );
            if (accessTokenRequest[0].status?.conditions[0].status) {
              warehouse.token = accessTokenRequest[0].status?.token;
              localStorage.setItem(
                'refreshToken',
                accessTokenRequest[0].status?.refreshToken ?? '',
              );
              localStorage.setItem(
                'accessToken',
                accessTokenRequest[0].status?.token ?? '',
              );
              return;
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error(e);
        }
      }
      this.$router.push({path: '/login'});
      throw err;
    });
    if (accessToken) {
      //查询平台信息
      const pConfig = (await platformConfigs.find(stage => stage)).find(
        () => true,
      );
      this.$store.state.pConfig = pConfig?.spec;

      //店铺管理员  应用管理员
      if (identity === '店铺' || identity === '财务') {
        let role = [] as Array<Role>;
        //查询角色
        if (identity === '店铺') {
          role = await roles.find(stage =>
            stage.$match(match =>
              match(
                f => f('spec')('name'),
                e => e.$eq('店铺管理员'),
              ),
            ),
          );
        } else {
          role = await roles.find(stage =>
            stage.$match(match =>
              match(
                f => f('spec')('name'),
                e => e.$eq('店铺财务'),
              ),
            ),
          );
        }
        const shopAuthority = (
          await roleMappings.find(stage =>
            stage.$match(match =>
              match(
                f => f('spec')('userId'),
                e => e.$eq(getUserId()),
              )(
                f => f('spec')('roleId'),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                e => e.$in(role.map(v => v._id!)),
              ),
            ),
          )
        ).map(v => v.spec.scopeId);
        localStorage.setItem('shopAuthority', EJSON.stringify(shopAuthority));
      } else {
        //查询用户身份
        //查询角色
        const role = await roles.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('name'),
              e => e.$eq('平台管理员'),
            ),
          ),
        );
        //查询用户拥有的角色
        const roleMapping = await roleMappings.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('userId'),
              e => e.$eq(getUserId()),
            )(
              f => f('spec')('roleId'),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              e => e.$in(role.map(v => v._id!)),
            ),
          ),
        );
        if (roleMapping.length > 0) {
          localStorage.setItem('roleName', '平台管理员');
        } else {
          localStorage.setItem('roleName', '应用管理员');
        }
      }
    }
  }
}
