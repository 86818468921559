/* eslint-disable */

import {ObjectId} from 'bson'

export namespace UsedOrderRequest {
  export const group: string = 'MaxCI';
  export const kind: string = 'UsedOrderRequest';
  export const version: string = 'v1';
 export const type: UsedOrderRequest = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 二手订单请求
 */
export interface UsedOrderRequest {
  /**
   * 规格
   */
  spec: UsedOrderDeliveryRequestSpec | UsedOrderUpdateAmountRequestSpec | UsedOrderCancelRequestSpec;
  status?: RequestResourceStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 二手订单配送请求规格
 */
export interface UsedOrderDeliveryRequestSpec {
  /**
   * 请求类型
   */
  type: "配送";
  expressInfo?: OrderExpressInfo;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 快递信息
 */
export interface OrderExpressInfo {
  /**
   * 快递单号
   */
  number: string;
  /**
   * 快递公司
   */
  company: string;
}
/**
 * 二手订单修改价格请求规格
 */
export interface UsedOrderUpdateAmountRequestSpec {
  /**
   * 请求类型
   */
  type: "修改价格";
  /**
   * 邮费
   */
  postage: number;
  /**
   * 商品金额
   */
  product: number;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 二手订单取消请求规格
 */
export interface UsedOrderCancelRequestSpec {
  /**
   * 请求类型
   */
  type: "取消";
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 订单ID
   */
  orderId: ObjectId;
  /**
   * 渠道ID
   */
  channelId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface RequestResourceStatus {
  conditions: Condition[];
  /**
   * 当前阶段
   */
  phase?: "成功" | "失败";
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
