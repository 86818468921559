/* eslint-disable */

import {ObjectId} from 'bson'

export namespace UsedProduct {
  export const group: string = 'MaxCI';
  export const kind: string = 'UsedProduct';
  export const version: string = 'v1';
 export const type: UsedProduct = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 二手商品
 */
export interface UsedProduct {
  spec: UsedProductSpec;
  status?: UsedProductStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 规格
 */
export interface UsedProductSpec {
  /**
   * 简介
   */
  remarks?: string;
  /**
   * 图片介绍(最多10张)
   */
  images:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string]
    | [string, string, string, string, string, string]
    | [string, string, string, string, string, string, string]
    | [string, string, string, string, string, string, string, string]
    | [string, string, string, string, string, string, string, string, string]
    | [string, string, string, string, string, string, string, string, string, string];
  /**
   * 上架
   */
  inService: boolean;
  /**
   * 售价
   */
  price: number;
  /**
   * 原价
   */
  originalPrice?: number;
  /**
   * 邮费
   */
  postage: number;
  /**
   * 标签
   */
  labels: string[];
  /**
   * 浏览量
   */
  views?: number;
  /**
   * 用户ID
   */
  userId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface UsedProductStatus {
  /**
   * 当前阶段
   */
  phase?: "就绪" | "禁用" | "售出";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
