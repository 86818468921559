import {interceptString} from './tool';
import {ObjectId} from 'bson';
import {Base64} from 'js-base64';
import {uploadRequests} from '../resources';
import {
  UploadImageBlur,
  UploadImageAutoOrient,
  UploadImageBright,
  UploadImageCircle,
  UploadImageContrast,
  UploadImageCrop,
  UploadImageFormat,
  UploadImageIndexCrop,
  UploadImageInterlace,
  UploadImageQuality,
  UploadImageResize,
  UploadImageRotate,
  UploadImageRoundedCorners,
  UploadImageSharpen,
} from '@/externals/MaxCI-UploadRequest-v1';
import {
  AgentOrderStatus,
  MallOrderStatus,
  RechargeOrderStatus,
  UsedOrderStatus,
  MemberCardOrderStatus,
  WithdrawalOrderStatus,
} from '@/externals/MaxCI-Order-v1';

//获取userId
const getUserId = () => {
  const accessToken = localStorage.getItem('accessToken') ?? '';
  const baseUseInfo = interceptString(accessToken, '.', '.');
  return ObjectId.createFromHexString(
    JSON.parse(Base64.decode(baseUseInfo)).aud,
  );
};
//图片请求
const uploadImg = async (
  channelId: ObjectId,
  applicationId: ObjectId,
  uploadPrivate: boolean,
  process: Array<
    | UploadImageAutoOrient
    | UploadImageBlur
    | UploadImageBright
    | UploadImageCircle
    | UploadImageContrast
    | UploadImageCrop
    | UploadImageFormat
    | UploadImageIndexCrop
    | UploadImageInterlace
    | UploadImageQuality
    | UploadImageResize
    | UploadImageRotate
    | UploadImageRoundedCorners
    | UploadImageSharpen
  >,
) => {
  return (
    await uploadRequests.create(
      [
        {
          spec: {
            type: '图片',
            process: process,
            private: uploadPrivate,
            userId: getUserId(),
            channelId: channelId,
            applicationId: applicationId,
          },
        },
      ],
      {
        watch: {
          filter: filter =>
            filter(
              f => f('operationType'),
              e => e.$eq('update'),
            )(
              f => f('fullDocument')('status')('phase'),
              e => e.$exists(true),
            ),
        },
        fullResource: true,
      },
    )
  ).find(() => true);
};
//计算字符串长度
const getStrLength = (str: string) => {
  let realLength = 0,
    charCode = -1;
  const len = str.length;
  for (let i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) realLength += 1;
    else realLength += 2;
  }
  return realLength;
};

//获取订单状态 AgentOrderStatus
const getOrderStatus = (
  status:
    | AgentOrderStatus
    | MallOrderStatus
    | RechargeOrderStatus
    | UsedOrderStatus
    | MemberCardOrderStatus
    | WithdrawalOrderStatus,
  type: string,
) => {
  let statusText = '';
  if (status.phase === '取消') {
    statusText = '已取消';
  } else if (status.phase === '完成') {
    statusText = '已完成';
  } else if (status.phase === '退款') {
    statusText = '已退款';
  }
  if (type === '商城' || type === '跑腿') {
    if (status.phase === '确认') {
      statusText = '待支付';
    } else if (status.phase === '支付' || status.phase === '接单') {
      statusText = '待发货';
    } else if (status.phase === '打包' || status.phase === '配送') {
      statusText = '待收货';
    }
  } else if (type === '充值' || type === '会员卡') {
    if (status.phase === '下单') {
      statusText = '待支付';
    } else if (status.phase === '支付') {
      statusText = '待充值';
    } else if (status.phase === '充值') {
      statusText = '充值中';
    }
  } else if (type === '二手') {
    if (status.phase === '下单') {
      statusText = '待支付';
    } else if (status.phase === '支付') {
      statusText = '待发货';
    } else if (status.phase === '配送') {
      statusText = '待收货';
    }
  }
  return statusText;
};
//rgba转16进制
const getHexColor = (color: string) => {
  if (color) {
    if (color.indexOf('#') > -1) {
      return color;
    } else {
      const values = color
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',');
      const a = parseFloat(values[3] || '1'),
        r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
      return (
        '#' +
        ('0' + r.toString(16)).slice(-2) +
        ('0' + g.toString(16)).slice(-2) +
        ('0' + b.toString(16)).slice(-2)
      );
    }
  } else {
    return '';
  }
};

export {getUserId, uploadImg, getStrLength, getOrderStatus, getHexColor};
