/* eslint-disable */

import {ObjectId} from 'bson'

export namespace ShopSettlement {
  export const group: string = 'MaxCI';
  export const kind: string = 'ShopSettlement';
  export const version: string = 'v1';
 export const type: ShopSettlement = null as any;
}
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

/**
 * 应用结算
 */
export interface ShopSettlement {
  /**
   * 规格
   */
  spec: ShopSettlementInputSpec | ShopSettlementOutputSpec;
  status?: ShopSettlementStatus;
  /**
   * 索引
   */
  _id: ObjectId;
  /**
   * 版本
   */
  apiVersion: string;
  metadata: Metadata;
}
/**
 * 店铺结算入账规格
 */
export interface ShopSettlementInputSpec {
  /**
   * 类型
   */
  type: "入账";
  /**
   * 结算日期
   */
  date: string;
  /**
   * 店铺余额
   */
  shopBalance?: number;
  /**
   * 应用余额
   */
  applicationBalance?: number;
  /**
   * 结算金额
   */
  settlementAmount: number;
  /**
   * 补贴金额
   */
  allowanceAmount: number;
  /**
   * 技术服务费
   */
  tecServiceAmount: number;
  /**
   * 配送服务费
   */
  deliveryServiceAmount: number;
  /**
   * 应用分成
   */
  applicationAmount: number;
  /**
   * 店铺分成
   */
  shopAmount: number;
  tecServiceRate: ServiceRate;
  deliveryServiceRate: ServiceRate1;
  totalOrderAmount: MallOrderAmount;
  totalRefundAmount: MallOrderAmount1;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 技术服务费率
 */
export interface ServiceRate {
  /**
   * 比例(万分之)
   */
  rate: number;
  /**
   * 保底(分)
   */
  minimum: number;
}
/**
 * 配送服务费率
 */
export interface ServiceRate1 {
  /**
   * 比例(万分之)
   */
  rate: number;
  /**
   * 保底(分)
   */
  minimum: number;
}
/**
 * 总订单费用信息
 */
export interface MallOrderAmount {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 满减金额
 */
export interface MallDiscount {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 优惠券金额
 */
export interface MallDiscount1 {
  /**
   * 店铺金额
   */
  shop: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 总订单退款金额
 */
export interface MallOrderAmount1 {
  /**
   * 包装金额
   */
  packing: number;
  /**
   * 配送金额
   */
  delivery: number;
  /**
   * 商品金额
   */
  product: number;
  fullReduction: MallDiscount;
  coupon: MallDiscount1;
  /**
   * 商品成本金额
   */
  productCost: number;
  /**
   * 总计金额
   */
  total: number;
  /**
   * 用户金额
   */
  user: number;
  /**
   * 应用金额
   */
  application: number;
}
/**
 * 店铺结算出账规格
 */
export interface ShopSettlementOutputSpec {
  /**
   * 类型
   */
  type: "出账";
  /**
   * 结算日期
   */
  date: string;
  /**
   * 店铺余额
   */
  shopBalance: number;
  /**
   * 应用余额
   */
  applicationBalance: number;
  /**
   * 应用提现
   */
  applicationAmount: number;
  /**
   * 店铺提现
   */
  shopAmount: number;
  /**
   * 店铺ID
   */
  shopId: ObjectId;
  /**
   * 应用ID
   */
  applicationId: ObjectId;
}
/**
 * 状态
 */
export interface ShopSettlementStatus {
  /**
   * 当前阶段
   */
  phase?: "待结算" | "已结算" | "已入账";
  conditions: Condition[];
}
export interface Condition {
  /**
   * 类型
   */
  type: string;
  /**
   * 状态
   */
  status: boolean | string;
  /**
   * 原因
   */
  reason?: string;
  /**
   * 消息
   */
  message?: string;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
}
/**
 * 元数据
 */
export interface Metadata {
  annotations?: StringMap;
  labels?: StringMap1;
  /**
   * 版本
   */
  version: number;
  /**
   * 创建时间
   */
  creationTimestamp: Date;
  /**
   * 更新时间
   */
  updationTimestamp?: Date;
  /**
   * 删除时间
   */
  deletionTimestamp?: Date;
  /**
   * 终结器列表
   */
  finalizers?: string[];
}
/**
 * 注解
 */
export interface StringMap {
  [k: string]: string;
}
/**
 * 标签
 */
export interface StringMap1 {
  [k: string]: string;
}
