/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'idphoto': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path d="M624 192v64c0 35.3-28.7 64-64 64h-96c-35.3 0-64-28.7-64-64v-64c0-8.8-7.2-16-16-16H64c-35.3 0-64 28.7-64 64v640c0 35.3 28.7 64 64 64h896c35.3 0 64-28.7 64-64V240c0-35.3-28.7-64-64-64H640c-8.8 0-16 7.2-16 16z m-48 264c0-4.4 3.6-8 8-8h304c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H584c-4.4 0-8-3.6-8-8v-48z m0 128c0-4.4 3.6-8 8-8h304c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H584c-4.4 0-8-3.6-8-8v-48z m0 128c0-4.4 3.6-8 8-8h304c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H584c-4.4 0-8-3.6-8-8v-48zM463.2 509.4c0.8 48.6-20.1 92.4-53.7 122.2-3.8 3.4-3.6 9.3 0.4 12.4 21.1 16.1 38.4 36.8 50.4 60.6 12.1 23.8 18.8 50.8 18.8 79.3 0 17.7-14.3 32-32 32h-288c-8.8 0-16.8-3.6-22.6-9.4-5.8-5.8-9.4-13.8-9.4-22.6 0-57.1 27.2-107.8 69.3-140 4-3.1 4.3-9 0.5-12.3-33-29.3-53.8-72.1-53.8-119.7 0-87.7 70.5-158.9 157.9-160 87.9-1 160.8 69.7 162.2 157.5z" p-id="5166"></path><path d="M560 112v128c0 8.8-7.2 16-16 16h-64c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16z" p-id="5167"></path>'
  }
})
